export const GET_CONTENT_MANAGEMENT = "GET_CONTENT_MANAGEMENT"
export const GET_CONTENT_MANAGEMENT_SUCCESS = "GET_CONTENT_MANAGEMENT_SUCCESS"
export const GET_CONTENT_MANAGEMENT_FAILURE = "GET_CONTENT_MANAGEMENT_FAILURE"

export const GET_CONTENT_MANAGEMENT_DETAIL = "GET_CONTENT_MANAGEMENT_DETAIL"
export const GET_CONTENT_MANAGEMENT_SUCCESS_DETAIL =
  "GET_CONTENT_MANAGEMENT_SUCCESS_DETAIL"
export const GET_CONTENT_MANAGEMENT_FAILURE_DETAIL =
  "GET_CONTENT_MANAGEMENT_FAILURE_DETAIL"

export const GET_LABELS = "GET_LABELS"
export const GET_LABELS_SUCCESS = "GET_LABELS_SUCCESS"
export const GET_LABELS_FAILURE = "GET_LABELS_FAILURE"

export const GET_POPULAR_UNI = "GET_POPULAR"
export const GET_POPULAR_UNI_SUCCESS = "GET_POPULAR_SUCCESS"
export const GET_POPULAR_UNI_FAILURE = "GET_POPULAR_FAILURE"

export const GET_CATEGORY = "GET_CATEGORY"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE"

export const GET_UNIVERSITY = "GET_UNIVERSITY"
export const GET_UNIVERSITY_SUCCESS = "GET_UNIVERSITY_SUCCESS"
export const GET_UNIVERSITY_FAILURE = "GET_UNIVERSITY_FAILURE"

export const GET_SPORTS = "GET_SPORTS"
export const GET_SPORTS_SUCCESS = "GET_SPORTS_SUCCESS"
export const GET_SPORTS_FAILURE = "GET_SPORTS_FAILURE"

export const POST_CONTENT_MANAGEMENT = "CONTENT_MANAGEMENT"
export const POST_CONTENT_MANAGEMENT_SUCCESS = "CONTENT_MANAGEMENT_SUCCESS"
export const POST_CONTENT_MANAGEMENT_FAIL = "CONTENT_MANAGEMENT_FAIL"

export const GET_SOCIAL_MEDIA_SHARE = "GET_SOCIAL_MEDIA_SHARE"
export const GET_SOCIAL_MEDIA_SHARE_SUCCESS = "GET_SOCIAL_MEDIA_SHARE_SUCCESS"
export const GET_SOCIAL_MEDIA_SHARE_FAILURE = "GET_SOCIAL_MEDIA_SHARE_FAILURE"

export const POST_NEW_CONTANT = "POST_NEW_CONTANT_SHARE"
export const POST_NEW_CONTANT_SUCCESS = "POST_NEW_CONTANT_SUCCESS"
export const POST_NEW_CONTANT_FAILURE = "POST_NEW_CONTANT_FAILURE"

export const PATCH_CONTANT_REQUEST = "PATCH_CONTANT_REQUEST"
export const PATCH_CONTANT_REQUEST_SUCCESS = "PATCH_CONTANT_REQUEST_SUCCESS"
export const PATCH_CONTANT_REQUEST_FAILURE = "PATCH_CONTANT_REQUEST_FAILURE"

export const DELETE_CONTANT_REQUEST = "DELETE_CONTANT_REQUEST"
export const DELETE_CONTANT_REQUEST_SUCCESS = "DELETE_CONTANT_REQUEST_SUCCESS"
export const DELETE_CONTANT_REQUEST_FAILURE = "DELETE_CONTANT_REQUEST_FAILURE"

export const GET_STATUS = "GET_STATUS"
export const GET_STATUS_SUCCESS = "GET_STATUS_SUCCESS"
export const GET_STATUS_FAILURE = "GET_STATUS_FAILURE"

export const GET_STATE = "GET_STATE"
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS"
export const GET_STATE_FAILURE = "GET_STATE_FAILURE"