// @ts-nocheck
import React from "react"
import ReactApexChart from "react-apexcharts"
import style from './style.module.scss'
import moment from "moment"

export const HeatmapApexChart = ({ loader }) => {
  const generateData = (count, yrange) => {
    var i = 0
    var series = []
    while (i < count) {
      var x = (i + 1).toString()
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

      series.push({
        x: x,
        y: y
      })
      i++
    }
    return series
  }

  const state = {
    series: [
      {
        name: "MO",
        data: generateData(17, {
          min: 0,
          max: 300
        })
      },
      {
        name: "TU",
        data: generateData(17, {
          min: 0,
          max: 250
        })
      },
      {
        name: "WE",
        data: generateData(17, {
          min: 0,
          max: 320
        })
      },
      {
        name: "TH",
        data: generateData(17, {
          min: 0,
          max: 180
        })
      },
      {
        name: "FR",
        data: generateData(17, {
          min: 0,
          max: 290
        })
      },
      {
        name: "SA",
        data: generateData(17, {
          min: 0,
          max: 190
        })
      },
      {
        name: "SU",
        data: generateData(17, {
          min: 0,
          max: 200
        })
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "heatmap",
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        colors: ["#141c27"],
        width: 3,
      },
      legend: {
        horizontalAlign: 'right',
        position: "top",
        fontWeight: 600,
        labels: {
          colors: "White",
        },
        itemMargin: {
          horizontal: 10,
          vertical: 0
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "white"
          }
        }
      },
      xaxis: {
        categories: ["1h", "2h", "3h", "4h", "5h", "6h", "7h", "8h", "9h", "10h", "11h", "12h", "13h", "14h", "15h", "16h", "17h"],
        labels: {
          style: {
            colors: "white"
          }
        }
      },
      plotOptions: {
        heatmap: {
          colorScale: {
            ranges: [{
              from: -50,
              to: 50,
              color: loader ? "#121617" : '#E0F3F3',
              name: '0-50',
            },
            {
              from: 50,
              to: 100,
              color: loader ? "#5C6F87" : '#DBDFE4',
              name: '50-100',
            },
            {
              from: 100,
              to: 500,
              color: loader ? "#1F2732" : '#65C5C2',
              name: '100-500',
            }
            ]
          }
        }
      }
    }
  }

  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type='heatmap'
      height={350}
    />
  )
}

export const BubbleApexChart = ({ loader, data }) => {
  const bubbleColors = loader ? ["#5C6F87", "#49586c"] : ["#00BCD3", "#4AAF57", "#9D28AC", "#F54336"]
  const BubbleDate = data?.map(e => e?.date && new Date(e?.date).getTime())

  if (BubbleDate?.length == 1) {
    const yesterdayDate = BubbleDate && new Date(BubbleDate[0])
    yesterdayDate?.setDate(yesterdayDate?.getDate() - 1);
    const TomorrowDate = BubbleDate && new Date(BubbleDate[0])
    TomorrowDate?.setDate(TomorrowDate?.getDate() + 1);
    BubbleDate?.push(yesterdayDate, TomorrowDate)
  }

  const Commission = data?.map(e => Number(e?.commission.toFixed()) || 0)
  const Affiliate = data?.map(e => Number(e?.affiliate.toFixed()) || 0)
  const Sales = data?.map(e => Number(e?.sales.toFixed()) || 0)
  const Views = data?.map(e => Number(e?.views.toFixed()) || 0)
  const maxVal = Commission && Affiliate && Sales && Views && Math.max(...Commission, ...Affiliate, ...Sales, ...Views)
  const YaxisValue = maxVal ? parseInt(maxVal) + parseInt(((10 / 100) * maxVal).toFixed(0)) : 70

  const generateData = (date, count, yrange) => {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = loader ? Math.floor(Math.random() * (750 - 1 + 1)) + 1 : date[i]
      var y = loader ? Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min : yrange && yrange[i];
      var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;
      series.push([x, y, z]);
      loader && (date += 86400000)
      i++;
    }
    return series;
  }

  const loaderData = generateData(new Date("11 Feb 2017 GMT").getTime(), 40, {
    min: 10,
    max: 60
  })

  const state = {
    series: [
      {
        name: "Commission",
        data: loader ? loaderData : generateData(BubbleDate, BubbleDate?.length, Commission && Commission)
      },
      {
        name: "Affiliate",
        data: loader ? loaderData : generateData(BubbleDate, BubbleDate?.length, Affiliate && Affiliate)
      },
      {
        name: "Sales",
        data: loader ? loaderData : generateData(BubbleDate, BubbleDate?.length, Sales && Sales)
      },
      {
        name: "View",
        data: loader ? loaderData : generateData(BubbleDate, BubbleDate?.length, Views && Views)
      }
    ],
    options: {
      chart: {
        height: 350,
        type: "bubble"
      },
      dataLabels: {
        enabled: false
      },
      colors: bubbleColors,
      fill: {
        opacity: 0.6
      },
      xaxis: {
        type: "datetime",
        labels: {
          showDuplicates: false,
          format: 'yyyyy/dd/MM',
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        horizontalAlign: 'right',
        position: "top",
        fontWeight: 600,
        labels: {
          colors: "White",
        },
        itemMargin: {
          horizontal: 10,
          vertical: 0
        },
      },
      grid: {
        show: true,
        position: "back",
        borderColor: '#55585A',
        strokeDashArray: 2,
        padding: {
          right: 35
        },
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
      },
      yaxis: {
        tickAmount: 9,
        max: YaxisValue,
        lables: {
          style: {
            colors: ["white"]
          }
        }
      },
      markers: {
        strokeWidth: 0
      }
    }
  }

  return (
    <div className={style.bubbleChartContainer}>
      {loader || data?.length ?
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bubble"
          height={350}
        />
        :
        <h1 className={style.noDataStyle}>No Data Found</h1>
      }
    </div>
  )
}

export const PieApexChart = ({ loader, data }) => {
  const pieColors = loader ? ["#1F2732"] : ["#1871EB", "#69C9D0", "#D82D7E", "#083777"]
  const Revenue = data?.cost_of_percent_of_net_revenue ? data?.cost_of_percent_of_net_revenue?.toFixed() : 20
  const click = data?.cost_per_click ? data?.cost_per_click?.toFixed() : 10
  const Conversion = data?.cost_per_conversion ? data?.cost_per_conversion?.toFixed() : 20
  const Cost = data?.total_cost_of_company ? data?.total_cost_of_company?.toFixed() : 50
  const state = {
    series: [
      Number(Revenue),
      Number(click),
      Number(Conversion),
      Number(Cost),
    ],
    options: {
      chart: {
        width: 380,
        type: "donut",
        margin: "auto"
      },
      dataLabels: {
        enabled: false
      },
      labels: ["Per Revenue Cost", "Per Click Cost", "Per Conversion Cost", "Total Cost"],
      colors: pieColors,
      legend: {
        horizontalAlign: 'center',
        position: "bottom",
        itemMargin: {
          horizontal: 25,
          vertical: 5
        },
        labels: {
          colors: "#BDBDBD",
        },
      },
      stroke: {
        show: true,
        colors: ["#141c27"],
        width: 4,
      },
      plotOptions: {
        pie: {
          donut: {
            size: '70%',
            labels: {
              show: true,
              total: {
                show: false,
                showAlways: false,
                label: 'Total',
                color: loader ? "#1F2732" : "#00BCD3",
                fontSize: "20px",
                fontWeight: 500,
              }
            }
          }
        }
      }
    }
  }

  return (
    <div className={style.donutChartContainer}>
      {loader || data ?
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="donut"
          width={380}
        />
        :
        <h1 className={style.noDataStyle}>No Data Found</h1>
      }
    </div>
  )
}

export const BarApexChart = ({ loader, data }) => {
  const chartData = data?.length > 20 ? data?.filter((_, i) => i % 2 === 1) : data
  const barColors = loader ? ["#121617", "#1F2732"] : ["#CDDC4C", "#FF981F", "#A4424B", "#65C5C2"]
  const XaxisDate = chartData ? chartData?.map(e => e?.date) : ["X 01", "X 02", "X 03", "X 04", "X 05", "X 06", "X 07"]
  const XaxisValue = loader ? ["X 01", "X 02", "X 03", "X 04", "X 05", "X 06", "X 07"] : XaxisDate
  const Orders = chartData?.map(e => Number(e?.orders.toFixed()) || 0)
  const Commission = chartData?.map(e => Number(e?.commission.toFixed()) || 0)
  const Conversion = chartData?.map(e => Number(e?.conversion.toFixed()) || 0)
  const Visits = chartData?.map(e => Number(e?.visits.toFixed()) || 0)
  const maxVal = Orders && Visits && Commission && Conversion && Math.max(...Orders, ...Visits, ...Commission, ...Conversion)
  const YaxisValue = maxVal ? maxVal + maxVal / 5 : 6
  const state = {
    series: [
      {
        name: "Orders",
        data: loader ? [2, 1.5, 3.7, 2.5, 2.3, 2, 3] : Orders
      },
      {
        name: "Commission",
        data: loader ? [0.5, 1, 1.3, 0.5, 1, 0.3, 0.3] : Commission
      },
      {
        name: "Conversion",
        data: loader ? [1, 3, 0.7, 0, 1, 0.6, 1.2] : Conversion
      },
      {
        name: "Visits",
        data: loader ? [1, 0.3, 0.3, 1, 1.3, 1.4, 0.4] : Visits
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: "25%",
          borderRadius: 10,
          rangeBarOverlap: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      colors: barColors,
      xaxis: {
        type: 'category',
        categories: XaxisValue,
        labels: {
          style: {
            colors: "#C9CFD7"
          },
          formatter: function (value) {
            var utc = moment(value)
            var isUTC = utc.isValid();
            if (isUTC) {
              return utc.format("DD MMM")
            } else {
              return value
            }

          }
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      fill: {
        opacity: 1
      },
      grid: {
        show: true,
        position: "back",
        borderColor: '#55585A',
        strokeDashArray: 2,
      },
      legend: {
        show: false
      },
      yaxis: {
        min: 1,
        tickAmount: 8,
        labels: {
          style: {
            colors: "#C9CFD7"
          },
          formatter: function (val) {
            return val.toFixed(0);
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    }
  }

  return (
    loader || chartData?.length ?
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={350}
      />
      :
      <h1 className={style.noDataStyle}>No Data Found</h1>
  )
}
