import axios from 'axios';
import { toast } from "react-hot-toast"


function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return response;
  }
  return response.json ? response.json() : response;
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  error.status = response.status;
  throw error;
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403 || error.response.status === 401) {
      toast.error("Your session has expired. Please login again.", {
        id: "card"
      })
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/login";
      }, 2000)
    }else if(error.response.status === 500 || error.response.status === 499){
      toast.error("Internal Server Error", {
        id: "card"
      })
    }
    return Promise.reject(error);
  }
);

export default (url, options) => axios(url, options).then(checkStatus).then(parseJSON);
