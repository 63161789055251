// @ts-nocheck
import React, { useState, useEffect } from "react"
import style from "./style.module.scss"
import HomeLayout from "../../layout/HomeLayout"
import { Col, DatePicker, Row } from "antd"
import TrendUpIcon from "../../assets/Images/trend-up.svg"
import SalesReportChart from "./Charts/SalesReport"
import Star from "../../assets/Images/Star.svg"
import TickSquare from "../../assets/Images/Tick-Square.svg"
import DollarIcon from "../../assets/Images/dollar-icon.png"

import {
  HeatmapApexChart,
  BubbleApexChart,
  PieApexChart,
  BarApexChart
} from "./Charts/audience"
import { EngagementRate } from "./dammyData"
import {
  EngagementRateSkeleton,
  LinksReportSkeleton,
  RegisterdUsersSkeleton,
  SalesClicksFollowersSkeleton
} from "./DashboardLoaders"
import { useDispatch } from "react-redux"
import { getDashboardData } from "./redux/actions"
import { useSelector } from "react-redux"
import moment from "moment"

const DashBoard = () => {

  const Initaildate = {
    start: "",
    end: ""
  }

  const [dateFilter, setDateFilter] = useState(Initaildate)
  const [selectDate, setSelectDate] = useState(null)

  const dispatch = useDispatch()
  const { dashboardData, loader } = useSelector(state => state.Dashboard)
  const Sales = dashboardData?.sales
  const Clicks = dashboardData?.click
  const Revenue = dashboardData?.revenue
  const PieChartData = dashboardData?.costs
  const SalesChartData = dashboardData?.sales_report
  const LinkReport = dashboardData?.top_link_report
  const RegisterUsers = dashboardData?.register_users
  const EarningReport = dashboardData?.earning_report
  const VisitorsData = dashboardData?.visitors
  const { RangePicker } = DatePicker

  useEffect(() => {
    dispatch(getDashboardData(dateFilter))
  }, [dateFilter])

  const onDateChange = (date) => {
    const minDate = date && date[0]?.$d
    const maxDate = date && date[1]?.$d
    const payload = {
      start: date ? moment(minDate).format("YYYY-MM-DD 00:00:00") : "",
      end: date ? moment(maxDate).format("YYYY-MM-DD HH:mm:ss") : ""
    }
    setDateFilter(payload)
    setSelectDate(date)
  }

  return (
    <>
      <HomeLayout heading="Dashboard">
        <div className={style.dashboardWrapper}>
          <Row>
            <Col lg={16} md={24} className={style.pageTitle}>
              {" "}
              <h1>Welcome, Admin</h1>
              <p>Overview your Business </p>
            </Col>
            <Col lg={8} md={24}>
              <RangePicker
                className={style.dateSelectStyle}
                onChange={onDateChange}
                value={selectDate}
                popupClassName={style.rangeStyle}
                format={"DD MMM YYYY"}
              />
            </Col>
          </Row>
          <Row gutter={20} className={style.dashboardMain}>
            <Col lg={24} span={24}>
              <div className={style.dashboardChart}>
                <h1>Analytics</h1>
                <BubbleApexChart loader={loader} data={VisitorsData} />
              </div>
            </Col>
          </Row>
          <Row gutter={20} style={{ marginTop: "25px" }}>
            <Col lg={8} md={12} span={24}>
              <div className={style.cardCOunter}>
                {loader ?
                  <SalesClicksFollowersSkeleton />
                  :
                  <>
                    <img
                      src={Star}
                      alt="icon"
                      width={24}
                      height={24}
                      className={style.icon}
                    />
                    <div className={style.content}>
                      <h4>Sales</h4>
                      <h1>
                        {Sales?.value || 0} <p>{Sales?.percentage || "---"}</p>
                      </h1>
                    </div>
                  </>
                }
              </div>
            </Col>
            <Col lg={8} md={12} span={24}>
              <div className={style.cardCOunter}>
                {loader ?
                  <SalesClicksFollowersSkeleton />
                  :
                  <>
                    <img
                      src={TickSquare}
                      alt="icon"
                      width={24}
                      height={24}
                      className={style.icon}
                    />
                    <div className={style.content}>
                      <h4>Clicks</h4>
                      <h1>
                        {Clicks?.value || 0} <p>{Clicks?.percentage || "---"}</p>
                      </h1>
                    </div>
                  </>
                }
              </div>
            </Col>
            <Col lg={8} md={12} span={24}>
              <div className={style.cardCOunter}>
                {loader ?
                  <SalesClicksFollowersSkeleton />
                  :
                  <>
                    <img
                      src={DollarIcon}
                      alt="icon"
                      width={24}
                      height={24}
                      className={style.icon}
                    />
                    <div className={style.content}>
                      <h4>Revenue</h4>
                      <h1>
                        {Revenue?.value.toLocaleString("en-US") || 0} <p>{Revenue?.percentage || "---"}</p>
                      </h1>
                    </div>
                  </>
                }
              </div>
            </Col>
          </Row>
          <Row gutter={20} style={{ marginTop: "25px" }}>
            <Col lg={12} span={24} className={style.grapgCol}>
              <div className={style.titleLine}>
                <h1> Total Cost</h1>
              </div>
              <div className={style.totalClicks}>
                <PieApexChart loader={loader} data={PieChartData} />
              </div>
            </Col>
            <Col lg={12} span={24} className={style.grapgCol}>
              <div className={style.titleLine}>
                <h1> Sales report </h1>
              </div>
              <div className={style.saleReport}>
                <SalesReportChart loader={loader} data={SalesChartData} />
              </div>
            </Col>
          </Row>
          <Row gutter={20} style={{ marginTop: "25px" }}>
            <Col lg={8} span={24}>
              <div className={style.titleLine}>
                <h1> Earnings report</h1>
              </div>
              <BarApexChart loader={loader} data={EarningReport} />
            </Col>
            <Col lg={8} span={24} className={style.grapgCol}>
              <div className={style.titleLine}>
                <h1> Top Links Report</h1>
                <div className={style.dashBoadSelect}>
                  <h1>Orders</h1>
                </div>
              </div>
              <div className={style.LinkReport}>
                <ul>
                  {loader ?
                    <LinksReportSkeleton />
                    :
                    LinkReport?.length ?
                      LinkReport?.map((item, index) => {
                        let url = item?.landing_page && new URL(item?.landing_page);

                        return (
                          <li key={index}>
                            <div className={style.liStyle}>
                              <a className={style.decStyle} href={item?.landing_page} target="blank">
                                {url && url.pathname != "/" ? url && url.pathname : item?.landing_page || "--"}
                              </a>
                              <p className={style.counts}>{item?.orders || 0}</p>
                            </div>
                          </li>
                        )
                      })
                      :
                      <h1 className={style.noDataStyle}>No Data Found</h1>
                  }
                </ul>
              </div>
            </Col>
            <Col lg={8} span={24} className={style.grapgCol}>
              <div className={style.titleLine}>
                <h1> Registered Users</h1>
              </div>
              <div className={style.registerdUsers}>
                <div className={style.item}>
                  {loader ?
                    <RegisterdUsersSkeleton />
                    :
                    <>
                      <h1>{RegisterUsers?.value || 0}</h1>
                      <h2>Users</h2>
                      <h4>
                        <span>{RegisterUsers?.percentage || "0%"}</span>
                        <img src={TrendUpIcon} width={12} height={12} />
                      </h4>
                    </>
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </HomeLayout>
    </>
  )
}
export default DashBoard
