// @ts-nocheck
import React, { useState, useEffect } from "react"
import style from "./style.module.scss"
import {
  Col,
  Drawer,
  Input,
  Row,
  Select,
  Upload,
  DatePicker,
  TimePicker,
  Button
} from "antd"
import {
  CloseOutlined,
  PlusOutlined,
  RightOutlined,
  UploadOutlined
} from "@ant-design/icons"
import CommonButton from "../../../common/button"
import CommonInput from "../../../common/input"
import arrowIcon from "../../../assets/Images/Arrow-Down.svg"
import { useDispatch, useSelector } from "react-redux"
import {
  getCategory,
  getUniversity,
  getSports,
  getLabels,
  getSocialMediaShare,
  postNewContant,
  patchContantRequest,
  patchContantRequestSuccess,
  getPostStatus
} from "../redux/action"
import AddmemberDrawer from "../AddmemberDrawer"
import { getUser } from "../../Management/redux/action"
import moment from "moment/moment"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import EditorToolbar, { modules, formats } from "./EditorToolsBar"
import dayjs from "dayjs"
import { toast as toastify } from "react-toastify"

const CreateContentDrawer = ({
  open,
  onClose,
  contentItems,
  updateDrawer,
  uniValue
}) => {
  const { TextArea } = Input
  const { Dragger } = Upload
  const dispatch = useDispatch()
  const [CategoryData, setCategory] = useState([])
  const [SportsData, setSports] = useState([])
  const [showDropDown, setShowDropDown] = useState(false)
  const [getDate, setGetDate] = useState(null)
  const [getTime, setGetTime] = useState("")
  const [lablesData, setLabelsData] = useState([])
  const [selectLable, setSelectLable] = useState([])
  const [note, setNote] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [selectCategory, setSelectCategory] = useState(null)
  const [selectSports, setSelectSports] = useState(null)
  const [SelectLableValue, setSelectLableValue] = useState(null)
  const [user, setUser] = useState([])
  const [status, setStatus] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState("To Do")
  const [AddMember, setaddMember] = useState(false)
  const [checkedVal, setCheckedVal] = useState(false)
  const [clickedItems, setClickedItems] = useState([])
  const [addUser, setAddUser] = useState([])
  const [statusButton, setStatusButton] = useState("To Do")
  const [filteredMembers, setFilteredMembers] = useState([])
  const [statusData, setStatusData] = useState({ value: 5, label: "To Do" })
  const [drawerOpen, setdrawerOpen] = useState(false)
  const [titleError, settitleError] = useState("")
  const [dateError, setDateError] = useState("")
  const [timeError, setTimeError] = useState("")
  const [fileList, setFileList] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  const [isDraft, setIsDraft] = useState(false)
  const [uploadFile, setUploadFile] = useState([])
  const [uploadTikTokVideo, setUploadTikTokVideo] = useState([])
  const [urlValid, setUrlValid] = useState(false)
  const [urlValue, setUrlValue] = useState("")
  const [featureError, setFeatureError] = useState("")
  const [tikTokError, setTikTokError] = useState("")
  const [quillOpen, setQuillOpen] = useState(false)

  const {
    category,
    university,
    sports,
    lables,
    socialMedia,
    postNewContantLoader,
    patchContantLoad,
    patchContant,
    getStatusData
  } = useSelector(state => state.ContentManagement)

  const userdata = useSelector(state => state.user)

  const { isUpdate, isUpdateData } = updateDrawer

  const AssigneeDisplayList = selectLable && selectLable?.slice(0, 2)
  const AssigneeTotalList = selectLable?.length - AssigneeDisplayList?.length

  useEffect(() => {
    dispatch(getCategory())
    dispatch(getUniversity())
    dispatch(getSports())
    dispatch(getLabels())
    dispatch(getSocialMediaShare())
    dispatch(getPostStatus())
    dispatch(getUser(`?university=${uniValue}`))
  }, [!category, !university, !sports, !lables, !getStatusData])

  useEffect(() => {
    if (patchContant.id) {
      onClose()
      emptyStates()
      dispatch(patchContantRequestSuccess(false))
    }
  }, [patchContant.id])

  const handleChangegetCategory = (value, label) => {
    setSelectCategory(value)
  }

  const handleChangeSport = (value, label) => {
    setSelectSports(value)
  }

  useEffect(() => {
    if (category) {
      const category_array = []
      category?.map((a, b) => {
        category_array.push({ value: a.id, label: a.name })
      })
      setCategory(category_array)
    }
    if (sports) {
      const sports_array = []
      sports?.map((a, b) => {
        sports_array.push({ value: a.id, label: a.name })
      })
      setSports(sports_array)
    }

    if (lables) {
      const label_array = []
      lables?.map((a, b) => {
        label_array.push({ value: a.id, label: a.name })
      })
      setLabelsData(label_array)
    }
    if (userdata.data.results) {
      const user_Array = []
      userdata.data.results?.map(a => {
        user_Array.push({ value: a.id, label: a?.first_name })
      })
      setUser(user_Array)
    }
    if (getStatusData) {
      const status_Array = []
      getStatusData?.map(a => {
        status_Array.push({ value: a.id, label: a?.name })
      })
      setStatus(status_Array)
    }
  }, [
    category,
    university,
    sports,
    lables,
    contentItems,
    userdata.data.results,
    getStatusData
  ])

  useEffect(() => {
    if (!open) {
      settitleError("")
    }
  }, [open])

  useEffect(() => {
    uploadFile.length && setFeatureError("")
    uploadTikTokVideo.length && setTikTokError("")
  }, [uploadFile, uploadTikTokVideo])

  useEffect(() => {
    if (isUpdate) {
      setSelectCategory(isUpdateData?.category?.id || null)
      setTitle(isUpdateData?.title || "")
      setDescription(isUpdateData?.description || "")
      setNote(isUpdateData?.notes || "")
      isUpdateData?.social_media_share?.map(val =>
        setClickedItems(prev => [...prev, val.id || ""])
      )
      setSelectSports(isUpdateData?.sports?.id || null)
      isUpdateData?.user && setFilteredMembers(isUpdateData?.user || [])
      setGetDate(
        (isUpdateData?.publish_date &&
          dayjs(isUpdateData?.publish_date, "YYYY-MM-DD")) ||
        null
      )
      isUpdateData?.publish_date &&
        setGetTime(
          moment(isUpdateData?.publish_date)
            .subtract(moment(isUpdateData?.publish_date).utcOffset(), "minute")
            .format("HH:mm") || ""
        )
      setCheckedVal(isUpdateData?.check_list || false)
      const getPathName =
        isUpdateData?.thumbnail && new URL(isUpdateData?.thumbnail)
      setUploadFile([
        {
          url: isUpdateData?.thumbnail,
          thumbUrl: isUpdateData?.thumbnail,
          name: getPathName?.pathname.slice(1)
        }
      ])
      const getVideoPathName =
        isUpdateData?.tiktok_video && new URL(isUpdateData?.tiktok_video)
      setUploadTikTokVideo([
        {
          url: isUpdateData?.tiktok_video,
          thumbUrl: isUpdateData?.tiktok_video,
          name: getVideoPathName?.pathname.slice(1)
        }
      ])

      setFileList([isUpdateData?.attachments])
      isUpdateData?.labels && setSelectLable(isUpdateData?.labels)
      isUpdateData?.shopify_product_url &&
        setUrlValue(isUpdateData?.shopify_product_url)
      isUpdateData?.user.length &&
        isUpdateData?.user.map(val => setAddUser(prv => [...prv, val.id]))
      if (isUpdateData?.status) {
        setStatusData({
          value: isUpdateData?.status?.id,
          label: isUpdateData?.status?.name
        })
        setSelectedStatus(isUpdateData?.status?.id)
        setStatusButton(isUpdateData?.status?.name)
      }
    } else {
      emptyStates()
    }
  }, [isUpdate])

  const emptyStates = () => {
    setSelectCategory(null)
    setTitle("")
    setDescription("")
    setNote("")
    setClickedItems([])
    setSelectSports(null)
    setFilteredMembers([])
    setGetDate(null)
    setGetTime("")
    setCheckedVal(false)
    setSelectLable([])
    setSelectLableValue(null)
    setFileList([])
    setSelectedUser(null)
    setSelectedStatus("To Do")
    setStatusData({ value: 5, label: "To Do" })
    setStatusButton("To Do")
    setAddUser([])
    setUploadFile([])
    setUploadTikTokVideo([])
    setUrlValue("")
    setUrlValid(false)
    setFeatureError(false)
    setTikTokError(false)
    setDateError("")
    setTimeError("")
  }

  const props = {
    onRemove: file => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: () => false,
    onChange: ({ file, fileList }) => {

      setFileList([fileList])
    },
    multiple: true
  }

  const uploadPicture = {
    onRemove: () => {
      setUploadFile([])
    },
    beforeUpload: () => false,
    onChange: ({ file, fileList }) => {
      setUploadFile(fileList)
    },
    multiple: false
  }

  const uploadVideo = {
    onRemove: () => {
      setUploadTikTokVideo([])
    },
    beforeUpload: () => false,
    onChange: ({ file, fileList }) => {
      setUploadTikTokVideo(fileList)
    },
    multiple: false
  }


  const addLable = () => {
    setShowDropDown(!showDropDown)
  }

  const handleChangelable = (value, obj) => {
    const isValueAlreadySelected = selectLable?.length
      ? selectLable?.some(e => e?.name == obj?.label)
      : false
    if (!isValueAlreadySelected) {
      if (
        !selectLable.some(selectedLabel => selectedLabel.value === obj.value)
      ) {
        setSelectLable([...selectLable, { id: obj.value, name: obj.label }])
      }
      setSelectLableValue(value)
    }
    setShowDropDown(false)
    setSelectLableValue(null)
  }

  const handleButtonClick = item => {
    item?.id === 98 && setUploadTikTokVideo([])
    if (clickedItems.includes(item.id)) {
      setClickedItems(
        clickedItems.filter(clickedItem => clickedItem !== item.id)
      )
    } else {
      setClickedItems([...clickedItems, item.id])
    }
  }
  const handleChangeAssign = (value, i) => {
    let index = addUser.findIndex(item => item == value)
    if (index == -1) {
      setAddUser(prevAddUser => [...prevAddUser, value])
    }
    setSelectedUser(value)
    setSelectSports("")
  }

  const handleChangeStatus = (value, i) => {
    setSelectedStatus(value)
    setStatusData(i)
    setStatusButton(i?.label)
  }

  const handleClose = () => {
    onClose()
    emptyStates()
  }

  const prevFiles = []
  console.log("fileList -----",fileList);
  fileList?.map(e =>

    e&&
    e.forEach(id => id?.id && prevFiles.push(id.id))
     )
  console.log("prevFiles =====",prevFiles);

  const CheckIsTikTok = clickedItems?.includes(98)

  const onSubmit = draft => {
    const valid = /^(ftp|http|https):\/\/[^ "]+$/.test(urlValue)
    if (
      title?.trim() === "" ||
      statusData?.label == "Scheduled" && !getDate ||
      statusData?.label == "Scheduled" && !getTime ||
      !uploadFile.length ||
      CheckIsTikTok && !uploadTikTokVideo.length ||
      urlValue.trim() === "" ||
      !statusData
    ) {
      title?.trim() === "" && settitleError("This field is required")
      !getDate && setDateError("This field is required")
      !getTime && setTimeError("This field is required")
      !uploadFile.length && setFeatureError("This field is required")
      CheckIsTikTok && !uploadTikTokVideo.length && setTikTokError("This field is required")
      urlValue.trim() === "" && setUrlValid("This field is required")
      toastify.info("The mandatory fields are not entirely filled out.")
    } else {
      const formData = new FormData()
      const dateTime = `${moment(getDate?.$d).format("YYYY-MM-DD")}T${getTime || "00:00"
        }`
      const a = fileList[0]
      for (let key in a) {

        a &&
          a[key]?.originFileObj &&
          formData.append(`attach${key}`, a[key]?.originFileObj)
      }
      a &&
        prevFiles?.length &&
        formData.append("attachments_ids", JSON.stringify(prevFiles))
      statusData?.label == "Scheduled" &&
        getDate &&
        formData.append("publish_date", dateTime)
      selectCategory &&
        formData.append("category", JSON.stringify(selectCategory))
      uniValue && formData.append("university", uniValue)
      formData.append("sports", selectSports ? JSON.stringify(selectSports) : '')
      formData.append("user_ids", JSON.stringify(addUser))
      const labelIds = selectLable?.map(e => e?.id)
      formData.append("labels_ids", JSON.stringify(labelIds))
      formData.append("social_media_share_ids", JSON.stringify(clickedItems))
      formData.append("title", title)
      formData.append("description", description)
      formData.append("check_list", checkedVal)
      formData.append("notes", note)
      const users = filteredMembers?.map(val => val?.id)
      formData.append("user_ids", JSON.stringify(users))
      formData.append(
        "local_timezone",
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      formData.append("status", statusData ? statusData?.value : 2)
      uploadFile.length &&
        uploadFile[0]?.originFileObj &&
        formData.append("thumbnail", uploadFile[0]["originFileObj"])
      uploadTikTokVideo.length &&
        uploadTikTokVideo[0]?.originFileObj &&
        formData.append("tiktok_video", uploadTikTokVideo[0]["originFileObj"])

      valid && formData.append("shopify_product_url", urlValue)
      if (isUpdate) {
        dispatch(
          patchContantRequest(
            isUpdateData?.id,
            formData,
            draft,
            uniValue,
            CheckScheduleDate,
            isUpdateData?.status
          )
        )
      } else {
        dispatch(
          postNewContant(
            formData,
            handleClose,
            draft,
            uniValue,
            CheckScheduleDate
          )
        )
      }
    }
  }

  const DraweronClose = () => {
    setaddMember(false)
  }

  const DraweronSave = (drawerMember, drawerLabel) => {
    setFilteredMembers(drawerMember)
    setSelectLable(drawerLabel)
    let userIDs = drawerMember?.map(e => e.id)
    setAddUser(userIDs)
    setSelectLableValue(null)
    setSelectedUser(null)
    setSelectedStatus(null)
    setaddMember(false)
  }

  const onDateChange = date => {
    setGetDate(date)
  }

  const onTimeChange = (date, stringData) => {
    setGetTime(stringData)
  }
  const handlePaste = e => {
    const clipboardData = e.clipboardData || window.clipboardData
    clipboardData.getData("text/html")
    e.preventDefault()
  }

  const disabledDate = current => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return current && current < yesterday
  }

  const onChangeURL = url => {
    setUrlValue(url.target.value)
    const valid = /^(ftp|http|https):\/\/[^ "]+$/.test(url.target.value)
    if (valid || url.target.value === "") {
      setUrlValid(false)
    } else {
      setUrlValid("Please enter a valid URL.")
    }
  }

  const handleDeleteMember = id => {
    const updatedMembers = filteredMembers?.filter(item => item?.id !== id)
    setFilteredMembers(updatedMembers)
    setAddUser(updatedMembers?.map(e => e?.id))
    setSelectedUser(null)
    setSelectedStatus(null)
  }
  const CheckScheduleDate =
    getDate &&
    moment(new Date()).format("YYYY-MM-DD-HH:mm") !=
    `${moment(getDate?.$d).format("YYYY-MM-DD")}-${getTime}`

  return (
    <>
      <Drawer
        className={style.contentDrawer}
        afterOpenChange={v => setQuillOpen(v)}
        title={
          <div className={style.topTitleDrawer}>
            <h4>
              Content Management
              <RightOutlined />
              <span className={style.activelink}>
                {isUpdate ? "Update Content" : "Create New Content"}
              </span>
            </h4>
            <div className={style.right}>
              <span onClick={handleClose}>
                <CloseOutlined />
              </span>
            </div>
          </div>
        }
        closeIcon={false}
        placement="right"
        onClose={handleClose}
        open={open}
        width={800}
        footer={
          <div className={style.btnblock}>
            <CommonButton
              title={`Save as ${statusButton && statusButton}`}
              onBtnClick={() => {
                setIsDraft(false)
                setIsSubmit(true)
                onSubmit(false)
              }}
              type="dark"
              loading={
                isSubmit ? postNewContantLoader || patchContantLoad : false
              }
            />
          </div>
        }
      >
        <div className={style.contentTiltle}>
          <CommonInput
            customClassName={style.inputStyle}
            onChange={e => {
              setTitle(e.target.value)
              settitleError("")
            }}
            placeholder="Less then 500 characters"
            type={"text"}
            value={title}
            maxLength={500}
          />
          {title.trim() === "" && titleError && (
            <p style={{ color: "red" }}>{titleError}</p>
          )}
        </div>
        <Row gutter={15}>
          <Col lg={16} md={24}>
            <p className={style.lableLight}>Labels</p>
            <div className={style.addLabels}>
              <PlusOutlined className={style.addIcon} onClick={addLable} />
              {!showDropDown &&
                AssigneeDisplayList?.length > 0 &&
                AssigneeDisplayList?.map((item, index) => {
                  return (
                    <CommonButton
                      key={index}
                      title={item.name}
                      customClass={style.featurebtn}
                      type="dark"
                      onBtnClick={() => {
                        setaddMember(!AddMember)
                        setdrawerOpen(true)
                      }}
                    />
                  )
                })}
              {!showDropDown && AssigneeTotalList > 0 && (
                <div className={style.assigneeItemStyle}>
                  +{AssigneeTotalList}
                </div>
              )}
              {showDropDown && (
                <Select
                  className={style.drawerselect}
                  suffixIcon={<img src={arrowIcon} />}
                  placeholder="Select"
                  style={{
                    width: 120
                  }}
                  popupClassName={style.dropdownstyle}
                  onChange={handleChangelable}
                  options={lablesData}
                  value={SelectLableValue}
                  showSearch={true}
                  onSearch={val => dispatch(getLabels(val))}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              )}
            </div>
          </Col>
          <Col lg={12} md={24}>
            <p className={style.lableLight}>Category</p>
            <Select
              className={style.drawerselect}
              suffixIcon={<img src={arrowIcon} />}
              placeholder="Select"
              popupClassName={style.dropdownstyle}
              onChange={handleChangegetCategory}
              options={CategoryData}
              value={selectCategory}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Col>
          <Col lg={12} md={24}>
            <p className={style.lableLight}>Status</p>
            <Select
              className={style.drawerselect}
              suffixIcon={<img src={arrowIcon} />}
              placeholder="Select"
              popupClassName={style.dropdownstyle}
              onChange={handleChangeStatus}
              options={status}
              value={selectedStatus}
            />
          </Col>
          <Col lg={12} md={24}>
            <p className={style.lableLight}>Select Sports</p>
            <Select
              className={style.drawerselect}
              suffixIcon={<img src={arrowIcon} />}
              placeholder="Select"
              popupClassName={style.dropdownstyle}
              allowClear={true}
              clearIcon={<CloseOutlined />}
              disabled={!!filteredMembers?.length}
              onChange={handleChangeSport}
              options={SportsData}
              value={selectSports}
              showSearch={true}
              onSearch={val => dispatch(getSports(val))}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Col>
          <Col lg={12} md={24}>
            <p className={style.lableLight}>Select Athletes</p>
            <Select
              className={style.drawerMultiSelect}
              suffixIcon={<img src={arrowIcon} />}
              placeholder="Select Athletes"
              style={{
                width: 120
              }}
              popupClassName={style.dropdownstyle}
              onChange={handleChangeAssign}
              options={user}
              value={selectedUser}
              disabled={selectSports}
              showSearch={true}
              onSearch={val => dispatch(getUser({ id: uniValue, value: val }))}
              filterOption={false}
            />
            <div className={style.avatorList}>
              {filteredMembers?.map(item => (
                <h1 className={style.memberStyling}>
                  {`${item?.first_name ? item?.first_name : "--"} ${item?.last_name ? item?.last_name : "--"
                    }`}
                  <CloseOutlined onClick={() => handleDeleteMember(item?.id)} />
                </h1>
              ))}
            </div>
          </Col>

          {statusData?.label == "Scheduled" && (
            <>
              <Col lg={12} md={12} sm={24}>
                <p className={style.lableLight}>Publish Date</p>
                <DatePicker
                  className={style.dateSelectStyle}
                  onChange={onDateChange}
                  disabledDate={disabledDate}
                  value={getDate}
                  format={"YYYY-MM-DD"}
                />
                {!getDate && dateError && (
                  <p style={{ color: "red" }}>{dateError}</p>
                )}
              </Col>
              <Col lg={12} md={12} sm={24}>
                <p className={style.lableLight}>Publish Time</p>
                <TimePicker
                  use12Hours
                  className={style.dateSelectStyle}
                  format="HH:mm"
                  value={getTime ? moment(getTime, "HH:mm") : ""}
                  onChange={onTimeChange}
                  onSelect={() => setGetTime("")}
                  disabled={!getDate}
                />
                {!getTime && timeError && (
                  <p style={{ color: "red" }}>{timeError}</p>
                )}
              </Col>
            </>
          )}

          <Col lg={24}>
            <Upload
              fileList={uploadFile}
              listType="picture"
              {...uploadPicture}
              picture={uploadFile[0]}
              thumbUrl={uploadFile[0]}
              accept=".png, .jpg, .jpeg"
              className={style.uploadPicture} // upload-list-inline
            >
              {uploadFile?.length === 0 && (
                <>
                  <Button icon={<UploadOutlined />}>
                    Select Feature Image
                  </Button>
                  <p style={{ color: "red" }}>{featureError}</p>
                </>
              )}
            </Upload>
          </Col>
          <Col lg={24}>
            <p className={`${style.lableLight} ${style.white}`}>Description</p>
            {quillOpen && (
              <>
                <EditorToolbar />
                <ReactQuill
                  value={description}
                  onChange={setDescription}
                  placeholder="Post Description"
                  onPaste={handlePaste}
                  modules={modules}
                  formats={formats}
                />
              </>
            )}
          </Col>
          <Col lg={24}>
            <p className={`${style.lableLight} ${style.white}`}>
              Shopify Product URL
            </p>
            <CommonInput
              customClassName={style.urlValidation}
              placeholder="URL"
              onChange={onChangeURL}
              erroMessage={urlValid}
              value={urlValue}
            />
          </Col>
          <Col lg={24}>
            <p className={`${style.lableLight} ${style.white}`}>Attachs</p>
            <Dragger
              fileList={fileList?.length > 0 ? [...fileList[0]] : fileList}
              {...props}
              className={style.uploadFiles}
            >
              <h3>Drag & Drop your file in here</h3>
              <p>
                OR <span>Browser your computer</span>
              </p>
            </Dragger>
          </Col>
          <Col lg={24} style={{ marginTop: 50 }}>
            {/*  <p className={`${style.lableLight} ${style.white}`}>
              Social Media Share
            </p>
            <div className={style.socialBtnList}>
              {socialMedia.map(item => (
                <CommonButton
                  key={item.id}
                  title={item.name}
                  type="dark"
                  customClass={
                    clickedItems.includes(item.id) ? "" : style.offBtn
                  }
                  onBtnClick={() => handleButtonClick(item)}
                />
              ))}{" "}
            </div>*/}
          </Col>
          {CheckIsTikTok &&
            <Col lg={24}>
              <Upload
                fileList={uploadTikTokVideo}
                listType="video"
                {...uploadVideo}
                accept="video/*"
                className={style.uploadVideo}
              >
                {uploadTikTokVideo.length === 0 && (
                  <>
                    <Button icon={<UploadOutlined />}>
                      Select Video
                    </Button>
                    <p style={{ color: "red" }}>{tikTokError}</p>
                  </>
                )}
              </Upload>
            </Col>
          }
          <Col lg={24}>
            <p className={`${style.lableLight} ${style.white}`}>Notes</p>
            <TextArea
              className={style.notetextarea}
              value={note}
              onChange={e => setNote(e.target.value)}
              placeholder="Typing detail your issues"
              autoSize={{ minRows: 3, maxRows: 3 }}
            />
          </Col>
        </Row>
      </Drawer>
      {
        <AddmemberDrawer
          Draweropen={AddMember}
          DraweronClose={DraweronClose}
          DraweronSave={DraweronSave}
          memberData={userdata.data.results}
          addUser={addUser}
          filteredMembers={filteredMembers}
          setFilteredMembers={setFilteredMembers}
          selectLable={selectLable}
          drawerOpen={drawerOpen}
        />
      }
    </>
  )
}

export default CreateContentDrawer
