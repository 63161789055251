import React, { useEffect, useState } from "react"
import style from "./style.module.scss"
import { Drawer } from "antd"
import { CloseOutlined, RightOutlined } from "@ant-design/icons"
import CommonButton from "../../../common/button"
import moreIcon from "../../../assets/Images/circleicon.svg"
import trash from "../../../assets/Images/trash.png"

const AddmemberDrawer = ({
  Draweropen,
  DraweronClose,
  DraweronSave,
  memberData,
  addUser,
  setFilteredMembers,
  filteredMembers,
  selectLable,
  drawerOpen
}) => {
  const [drawerMember, setDrawerMember] = useState([])
  const [drawerLabel, setDrawerLbel] = useState([])

  useEffect(() => {
    setFilteredMembers(memberData?.filter(item => addUser.includes(item.id)))
  }, [addUser, memberData])

  useEffect(() => {
    filteredMembers && setDrawerMember(filteredMembers)
    selectLable && setDrawerLbel(selectLable)
  }, [Draweropen])

  const handleDeleteItem = itemId => {
    const updatedMembers = drawerMember.filter(item => item.id !== itemId)
    setDrawerMember(updatedMembers)
  }

  const handleDeletelable = itemId => {
    const updatedMembers = drawerLabel?.filter(item => item?.id !== itemId)
    setDrawerLbel(updatedMembers)
  }

  return (
    <>
      <Drawer
        className={style.contentDrawer}
        title={
          <div className={style.topTitleDrawer}>
            <h4>
              Content Management
              <RightOutlined />
              <span className={style.activelink}> Create New Content</span>
            </h4>
            <div className={style.right}>
              {/* <img src={moreIcon} /> */}
              <span onClick={DraweronClose}>
                <CloseOutlined />
              </span>
            </div>
          </div>
        }
        closeIcon={false}
        placement="right"
        onClose={DraweronClose}
        open={Draweropen}
        width={800}
        footer={
          <div className={style.btnblock}>
            <CommonButton
              title="Save"
              onBtnClick={() => DraweronSave(drawerMember, drawerLabel)}
              type="dark"
            />
            <CommonButton
              title="Cancel"
              type="light"
              onBtnClick={DraweronClose}
            />
          </div>
        }
      >
        <div className={style.addmemberDiv}>
          <span className={style.addmember}>
            {drawerOpen ? (
              <>Add Lable({selectLable?.length})</>
            ) : (
              <>Add Member({drawerMember?.length})</>
            )}
          </span>
        </div>
        {drawerOpen &&
          drawerLabel?.map((item, index) => (
            <div className={style.memberDiv}>
              <div className={style.flex}>
                <CommonButton
                  title={item?.name}
                  type="dark"
                  customClass={style.btnsStyle}
                />
              </div>
              <img
                src={trash}
                width={20}
                onClick={() => handleDeletelable(item?.id)}
              />
            </div>
          ))}

        <div>
          {!drawerOpen &&
            drawerMember &&
            drawerMember?.map(item => (
              <div key={item.id} className={style.memberDiv}>
                <div className={style.flex}>
                  {item?.picture ? (
                    <img
                      src={item.picture}
                      width={40}
                      height={40}
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <div className={style.pictureStyle}>
                      {item?.first_name ? item?.first_name.slice(0, 1) : "F"}
                      {item?.last_name ? item?.last_name.slice(0, 1) : "L"}
                    </div>
                  )}
                  <div style={{ marginLeft: 10 }}>
                    <p className={style.name}>
                      {item.first_name} {item.last_name}
                    </p>
                    <p className={style.email}>{item.email}</p>
                  </div>
                </div>
                <img
                  src={trash}
                  width={20}
                  onClick={() => handleDeleteItem(item.id)}
                />
              </div>
            ))}
        </div>
      </Drawer>
    </>
  )
}

export default AddmemberDrawer
