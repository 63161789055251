import React, { useState } from 'react'
import style from './style.module.scss'
import HomeLayout from '../../../../web/src/layout/HomeLayout'
import SalesTable from './Salestable'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { affiliateUser, getSalesList } from './redux/action'
import { useSelector } from 'react-redux'
import { Col, DatePicker, Row, Select } from 'antd'
import CommonInput from '../../../../web/src/common/input'
import arrowIcon from "../../assets/Images/Arrow-Down.svg"
import { getUser } from '../Management/redux/action'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import moment from 'moment'


const SalesManagement = () => {

    const { RangePicker } = DatePicker;

    const [searchOrder, setSearchOrder] = useState(null)
    const [selectOrder, setSelectOrder] = useState(null)
    const [allUser, setAllUser] = useState([])
    const [selectDate, setSelectDate] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)

    const { salesManagementData, affiliateUserData, loader } = useSelector(state => state?.SalesManagement)

    const UsersList = affiliateUserData?.affiliates ? affiliateUserData?.affiliates : affiliateUserData

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(affiliateUser(''))
    }, [])

    useEffect(() => {
        dispatch(getSalesList(searchOrder, selectOrder, startDate, endDate, limit, offset))
    }, [searchOrder, selectOrder, startDate, endDate, limit, offset])

    const handleSearchUser = (value) => {
        dispatch(affiliateUser(value))
    }

    useEffect(() => {
        if (UsersList) {
            const users_array = []
            UsersList?.map((a, b) => {
                const id = a?.id ? a?.id : a?.affiliate_id
                if (a?.name) {
                    users_array?.push({ value: id, label: a?.name })
                }
            })
            setAllUser(users_array)
        }
    }, [UsersList])

    const handleSelectUser = (value) => {
        setSelectOrder(value)
    }

    const onDateChange = (date) => {
        const minDate = date && date[0]?.$d
        const maxDate = date && date[1]?.$d
        setStartDate(moment(minDate).format("YYYY-MM-DD"))
        setEndDate(moment(maxDate).format("YYYY-MM-DD"))
        setSelectDate(date)
    }

    return (
        <HomeLayout heading="Sales Management" subHeading="Sales">
            <div className={style.mainSalesContainer}>
                <Row className={style.filtersSummaryContainer}>
                    <Col lg={12} md={24} className={style.filtersContainer}>
                        <h1>Filters</h1>
                        <Row gutter={24} className={style.filterStyle}>
                            <Col span={24}>
                                <Select
                                    showSearch
                                    className={style.selectorStyle}
                                    suffixIcon={<img src={arrowIcon} />}
                                    placeholder="Filter by Affiliate"
                                    popupClassName={style.dropdownstyle}
                                    onChange={handleSelectUser}
                                    onSearch={handleSearchUser}
                                    filterOption={false}
                                    allowClear
                                    options={allUser}
                                    value={selectOrder}
                                />
                            </Col>
                            <Col span={24} lg={12} md={12} sm={24} style={{ marginBottom: '10px' }}>
                                <CommonInput
                                    customClassName={style.inputStyle}
                                    onChange={e => {
                                        setSearchOrder(e.target.value)
                                    }}
                                    placeholder="Search for order"
                                    type={"text"}
                                    value={searchOrder}
                                />
                            </Col>
                            <Col span={24} lg={12} md={12} sm={24} style={{ marginBottom: '10px' }}>
                                <RangePicker
                                    className={style.dateSelectStyle}
                                    onChange={onDateChange}
                                    value={selectDate}
                                    format={"YYYY-MM-DD"}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <SkeletonTheme baseColor="#1f2732" highlightColor="#5c6f87">
                        <Col span={24} lg={12} md={24} className={style.summaryContainer}>
                            <h1>Summary</h1>
                            <div className={style.summaryStyle}>
                                <div className={style.summarydata}>
                                    <h1> {loader ?
                                        <Skeleton
                                            height={10}
                                            width={40}
                                        /> :
                                        salesManagementData?.aggregate?.order_count}</h1>
                                    <h2>Sales</h2>
                                </div>
                                <div className={style.summarydata}>
                                    <h1>
                                        {loader ?
                                            <Skeleton
                                                height={10}
                                                width={100}
                                            /> :
                                            `$${salesManagementData?.aggregate?.order_total || 0}`}
                                    </h1>
                                    <h2>Revenue</h2>
                                </div>
                                <div className={style.summarydata}>
                                    <h1>
                                        {loader ?
                                            <Skeleton
                                                height={10}
                                                width={100}
                                            /> :
                                            `$${salesManagementData?.aggregate?.affiliate_commission || 0}`}
                                    </h1>
                                    <h2>Commission</h2>
                                </div>
                            </div>
                        </Col>
                    </SkeletonTheme>
                </Row>
                <SalesTable tableData={salesManagementData} setLimit={setLimit} setOffset={setOffset} loading={loader} />
            </div>
        </HomeLayout>)
}

export default SalesManagement