// import activeAdd from "../../Images/assets/sidebarImg"
// import add from "src/assets/sidebarImg/add.png"
import add from "../../src/assets/sidebarImg/add.png"
import analytics from "../../src/assets/sidebarImg/analytics.svg"
import analyticsActive from "../../src/assets/sidebarImg/analyticsActive.svg"
import activeAdd from "../../src/assets/sidebarImg/activeAdd.png"
import dashboard from "../../src/assets/sidebarImg/dashboard.svg"
import dashboardActive from "../../src/assets/sidebarImg/dashboardActive.svg"
import profile from "../../src/assets/sidebarImg/profile.png"
import management from "../../src/assets/sidebarImg/management.png"
import users from "../../src/assets/sidebarImg/Users.svg"
import managementActive from "../../src/assets/sidebarImg/managementActive.png"
import logout from "../../src/assets/sidebarImg/Logout.svg"
import card from "../../src/assets/sidebarImg/creditCard.svg"
import cardActive from "../../src/assets/sidebarImg/creditCardActive.svg"
import Mask from "../../src/assets/sidebarImg/Mask.svg"
import MaskActive from "../../src/assets/sidebarImg/MaskActive.svg"
import profileIcon from "../../src/assets/sidebarImg/profile.svg"
import profileIconActive from "../../src/assets/sidebarImg/profileActive.svg"
import setting from "../../src/assets/sidebarImg/setting.svg"
import settingActive from "../../src/assets/sidebarImg/settingActive.svg"
import menuAddIcon from "../../src/assets/Images/addIcon.png"
import chatMessage from "../../src/assets/Images/message.png"
import chatMessageActive from "../../src/assets/Images/message-active.png"
import messages from "../../src/assets/sidebarImg/message.svg"
import messagesActive from "../../src/assets/sidebarImg/messageActive.svg"
import mail from "../../src/assets/sidebarImg/mail.svg"
import mailActive from "../../src/assets/sidebarImg/mailActive.svg"
import support from "../../src/assets/sidebarImg/support.svg"
import supportActive from "../../src/assets/sidebarImg/supportActive.svg"

export const Images = {
  add,
  analytics,
  analyticsActive,
  profile,
  management,
  managementActive,
  Mask,
  dashboard,
  activeAdd,
  logout,
  card,
  cardActive,
  users,
  profileIcon,
  profileIconActive,
  setting,
  settingActive,
  menuAddIcon,
  messages,
  messagesActive,
  mail,
  mailActive,
  support,
  supportActive,
  dashboardActive,
  MaskActive,
  chatMessage,
  chatMessageActive
}
