import React, { useState } from "react"
import HomeLayout from "../../layout/HomeLayout"
import ContentList from "./ContentListing"
import ContentDataContainer from "./ContentContainer"

const ContentManagement = () => {
  const [uniSuccess, setUniSuccess] = useState(null)
  const [uniValue, setUniValue] = useState(null)

  return (
    <>
      <HomeLayout heading="Content Management">
        {uniSuccess ?
          <ContentList uniValue={uniValue} changeUni={setUniSuccess} />
          :
          <ContentDataContainer uniSuccess={setUniSuccess} UniValue={setUniValue} />
        }
      </HomeLayout>
    </>
  )
}
export default ContentManagement
