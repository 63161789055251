import {
  POST_LOGIN,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_FAIL,
  ACCESS_TOKEN,
  LOGOUT
} from "./actionTypes"

export const postLogin = (data, navigate) => ({
  type: POST_LOGIN,
  data,
  navigate
})

export const postLoginSuccess = data => ({
  type: POST_LOGIN_SUCCESS,
  data
})

export const setAccessToken = data => ({
  type: ACCESS_TOKEN,
  data
})

export const postLoginFail = error => ({
  type: POST_LOGIN_FAIL,
  error
})

export const logOut = data => ({
  type: LOGOUT,
  data
})
