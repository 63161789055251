import { all, takeLatest, put, call } from "redux-saga/effects"
import XHR from "../../../utils/XHR"
import { BASE_URL } from "../../../config/app"
import { toast as toastify } from "react-hot-toast"
import {
    getHelpCenterFailure,
    getHelpCenterSuccess,
    patchHelpCenterFailure,
    patchHelpCenterSuccess
} from "./action"
import {
    GET_HELP_CENTER,
    PATCH_HELP_CENTER
} from "./actionTypes"

async function getHelpCenterApi(data) {
    const URL = `${BASE_URL}/api/v1/tickets/`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
        },
        method: "GET"
    }
    return XHR(URL, options)
}

async function patchHelpCenterApi(id, data) {
    const URL = `${BASE_URL}/api/v1/tickets/${id}/`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
        },
        method: "PATCH",
        data: data
    }
    return XHR(URL, options)
}

function* onGetHelpCenterData({ data }) {
    try {
        const response = yield call(getHelpCenterApi, data)
        yield put(getHelpCenterSuccess(response.data))
    } catch (error) {
        const { response } = error
        yield put(getHelpCenterFailure(response.data))
    }
}


function* onPatchHelpCenterData({ id, data, success }) {
    try {
        const response = yield call(patchHelpCenterApi, id, data)
        yield put(patchHelpCenterSuccess(response.data))
        toastify.success("Your message has been sent successfully")
        success()
    } catch (error) {
        const { response } = error
        yield put(patchHelpCenterFailure(response.data))
    }
}

export default all([
    takeLatest(GET_HELP_CENTER, onGetHelpCenterData),
    takeLatest(PATCH_HELP_CENTER, onPatchHelpCenterData)
])
