import {
  POST_LOGIN,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_FAIL,
  ACCESS_TOKEN,
  LOGOUT
} from "./actionTypes"

const initialState = {
  data: false,
  loader: false,
  error: false,
  accessToken: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_LOGIN:
      return {
        ...state,
        loader: true
      }
    case POST_LOGIN_SUCCESS:
      return {
        ...state,
        data: action.data,
        loader: false
      }
    case ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.data
      }
    case POST_LOGIN_FAIL:
      return {
        ...state,
        error: action.error,
        loader: false
      }
    case LOGOUT:
      return {
        ...state,
        data: false,
        loader: false,
        accessToken: false
      }
    default:
      return state
  }
}
