import { all, takeLatest, put, call } from "redux-saga/effects"
import {
  GET_CONTENT_MANAGEMENT,
  GET_LABELS,
  GET_CATEGORY,
  GET_UNIVERSITY,
  GET_SPORTS,
  GET_SOCIAL_MEDIA_SHARE,
  POST_NEW_CONTANT,
  GET_CONTENT_MANAGEMENT_DETAIL,
  PATCH_CONTANT_REQUEST,
  DELETE_CONTANT_REQUEST,
  GET_POPULAR_UNI,
  GET_STATUS,
  GET_STATE
} from "./actionTypes"
import {
  getContentManagementSuccess,
  getContentManagementFailure,
  getLabelsSuccess,
  getLabelsFailure,
  getCategorySuccess,
  getCategoryFailure,
  getUniversitySuccess,
  getUniversityFailure,
  getSportsSuccess,
  getContentManagement,
  getSportsFailure,
  getSocialMediaShareSuccess,
  getSocialMediaShareFailure,
  postNewContantSuccess,
  postNewContanteFailure,
  getContentManagementSuccessDetail,
  getContentManagementFailureDetail,
  patchContantRequestSuccess,
  patchContantRequestFailure,
  deleteContantRequestSuccess,
  deleteContantRequestFailure,
  getPopularUniSuccess,
  getPopularUniFailure,
  getStatusSuccess,
  getStatusFailure,
  getStateSuccess,
  getStateFailure
} from "./action"
import XHR from "../../../utils/XHR"
import { BASE_URL } from "../../../config/app"
import { toast as toastify } from 'react-toastify';

// GET Content Management

async function getContentManagementSaga(data, search) {
  let URL = `${BASE_URL}/api/v1/admin/content_management/?`;

  let university = data.selectUniversty ? `filter_by_university=${data.selectUniversty}` : ''
  let selectCategory = data.selectCategory ? `&filter_by_category=${data.selectCategory}` : ''
  let searchFilter = data.search ? `&search=${data.search}` : ''

  if (data) {
    URL += `${university}${selectCategory}${searchFilter}`;
  }
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

async function getContentManagementDetailSaga(id, data) {
  const URL = `${BASE_URL}/api/v1/admin/content_management/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

// GET Labels

async function getLabelsSaga(data) {
  const URL = `${BASE_URL}/api/v1/admin/get_labels/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

// GET Category

async function getCategorySaga(data) {
  const URL = `${BASE_URL}/api/v1/admin/get_category/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

// GET University

async function getUniveristySaga(data) {
  const UniData  = data ? data : ""
  const URL = `${BASE_URL}/api/v1/admin/get_university/?search=${UniData}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}


// GET Popular

async function getPopularUniSaga(data) {
  const URL = `${BASE_URL}/api/v1/admin/popular_universities/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

// GET Sports

async function getSportsSaga(data) {
  const URL = `${BASE_URL}/api/v1/admin/get_sports/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

// GET Social Media Share

async function getSocialMediaShareSaga(data) {
  const URL = `${BASE_URL}/api/v1/admin/get_social_media/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

// GET Status

async function getStatusSaga(data) {
  const URL = `${BASE_URL}/api/v1/admin/get_status/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

// GET State

async function getStateSaga(data) {
  const URL = `${BASE_URL}/api/v1/get_states/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

// Post new Contant

async function postNewContant(data) {
  const URL = `${BASE_URL}/api/v1/admin/content_management/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function patchContant(id, data) {
  const URL = `${BASE_URL}/api/v1/admin/content_management/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

async function deletContantAPI(id) {
  const URL = `${BASE_URL}/api/v1/admin/content_management/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* contentManagementData({ data }) {
  try {
    const response = yield call(getContentManagementSaga, data)
    yield put(getContentManagementSuccess(response.data))
  } catch (error) {
    yield put(getContentManagementFailure(error.response))
  }
}

// Response GET Content Management

function* contentManagementDetailData({ id, data }) {
  try {
    const response = yield call(getContentManagementDetailSaga, id, data)
    yield put(getContentManagementSuccessDetail(response.data))
  } catch (error) {
    yield put(getContentManagementFailureDetail(error.response))
  }
}

// Response GET Labels

function* getLabelsData({ data }) {
  try {
    const response = yield call(getLabelsSaga, data)
    yield put(getLabelsSuccess(response.data))
  } catch (error) {
    yield put(getLabelsFailure(error.response))
  }
}

// Response GET Category

function* getCategoryData({ data }) {
  try {
    const response = yield call(getCategorySaga, data)
    yield put(getCategorySuccess(response.data))
  } catch (error) {
    yield put(getCategoryFailure(error.response))
  }
}

// Response GET University

function* getUniversityData({ data }) {
  try {
    const response = yield call(getUniveristySaga, data)
    yield put(getUniversitySuccess(response.data))
  } catch (error) {
    yield put(getUniversityFailure(error.response))
  }
}

// Response GET Popular

function* getPopularUniData({ data }) {
  try {
    const response = yield call(getPopularUniSaga, data)
    yield put(getPopularUniSuccess(response.data))
  } catch (error) {
    yield put(getPopularUniFailure(error.response))
  }
}

// Response GET Sports

function* getSportsData({ data }) {
  try {
    const response = yield call(getSportsSaga, data)
    yield put(getSportsSuccess(response.data))
  } catch (error) {
    yield put(getSportsFailure(error.response))
  }
}

// Response GET Social Media Share

function* getSocialMediaShareData({ data }) {
  try {
    const response = yield call(getSocialMediaShareSaga, data)
    yield put(getSocialMediaShareSuccess(response.data))
  } catch (error) {
    yield put(getSocialMediaShareFailure(error.response))
  }
}

// Post New Contant

function* postNewContantData({ data, handleClose, draft, uniValue, CheckScheduleDate }) {
  try {
    const response = yield call(postNewContant, data)
    yield put(postNewContantSuccess(response.data))
    yield put(getContentManagement({ selectUniversty: uniValue }))
    handleClose()
    draft ?
      toastify.info("Content added to Drafts") :
      CheckScheduleDate ?
        toastify.success("Content Scheduled successfully") :
        toastify.success("Content added successfully")
  } catch (error) {
    yield put(postNewContanteFailure(error.response))
    let errorMsg = "Some thing went wrong"
    if (error?.response?.data?.category) {
      errorMsg = `category: ${error?.response?.data?.category[0]}`
    } else if (error?.response?.data?.publish_date) {
      errorMsg = `Date time has wrong format.`
    }
    else if (error?.response?.data?.sports) {
      errorMsg = `sports: ${error?.response?.data?.sports[0]}`
    }
    else if (error?.response?.data?.university) {
      errorMsg = `university: ${error?.response?.data?.university[0]}`
    }
    toastify.error(errorMsg)
  }
}

function* patchContantAPI({ id, data, draft, uniValue, CheckScheduleDate, status }) {
  try {
    const response = yield call(patchContant, id, data)
    yield put(patchContantRequestSuccess(response.data))
    yield put(getContentManagement({ selectUniversty: uniValue }))
    draft ?
      status == "Pending" ?
        toastify.success("Content updated to pending") :
        toastify.success("Content updated to drafts") :
      CheckScheduleDate && status != "PUBLISHED" ?
        toastify.success("Content scheduled successfully") :
        toastify.success("Content updated successfully")
  } catch (error) {
    const { response } = error
    yield put(patchContantRequestFailure(response.data))
    if (response.data.thumbnail) {
      toastify.error(response.data.thumbnail[0])
    } else {
      toastify.error("An Error occured")
    }
  }
}

function* deleteContant({ id, onClose, uniValue, handleCancelDelete }) {
  try {
    yield call(deletContantAPI, id)
    yield put(deleteContantRequestSuccess())
    yield put(getContentManagement({ selectUniversty: uniValue }))
    onClose()
    toastify.success("Deleted Successfully")
    handleCancelDelete()
  } catch (error) {
    yield put(deleteContantRequestFailure(error.response))
    toastify.error("An Error occured")
  }
}

function* getPostStatusData({ data }) {
  try {
    const response = yield call(getStatusSaga, data)
    yield put(getStatusSuccess(response.data))
  } catch (error) {
    yield put(getStatusFailure(error.response))
    toastify.error("An Error occured")
  }
}

function* getPostStateData({ data }) {
  try {
    const response = yield call(getStateSaga, data)
    yield put(getStateSuccess(response.data))
  } catch (error) {
    yield put(getStateFailure(error.response))
    toastify.error("An Error occured")
  }
}

export default all([
  takeLatest(GET_CONTENT_MANAGEMENT, contentManagementData),
  takeLatest(GET_CONTENT_MANAGEMENT_DETAIL, contentManagementDetailData),
  takeLatest(GET_LABELS, getLabelsData),
  takeLatest(GET_CATEGORY, getCategoryData),
  takeLatest(GET_UNIVERSITY, getUniversityData),
  takeLatest(GET_POPULAR_UNI, getPopularUniData),
  takeLatest(GET_SPORTS, getSportsData),
  takeLatest(GET_SOCIAL_MEDIA_SHARE, getSocialMediaShareData),
  takeLatest(POST_NEW_CONTANT, postNewContantData),
  takeLatest(PATCH_CONTANT_REQUEST, patchContantAPI),
  takeLatest(DELETE_CONTANT_REQUEST, deleteContant),
  takeLatest(GET_STATUS, getPostStatusData),
  takeLatest(GET_STATE, getPostStateData),
])
