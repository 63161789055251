import Header from "../../Components/Header"
import React from "react"
import SidebarMenu from "../../Components/SidebarMenu"

import style from "./style.module.scss"
import { Layout } from "antd"
const { Content } = Layout;
const HomeLayout = ({ heading, subHeading, children }) => {
  return (
    <Layout hasSider className={style.mainLayout}>
      <SidebarMenu />
      <Content>
        <Header
          heading={heading}
          subHeading={subHeading}
        />
        <div className={style.mainbodyWrapper}>
          {children}
        </div>
      </Content>

    </Layout>

  )
}

export default HomeLayout
