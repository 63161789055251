import React from "react"
import style from "./style.module.scss"
import HomeLayout from "../../layout/HomeLayout"


const Anayltics = () => {
  return (
    <>
      <HomeLayout>
      <div className={style.mainWrapper}>
        <h1>Mail</h1>
      </div>
      </HomeLayout>
    </>
  )
}
export default Anayltics
