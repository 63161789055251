import { Table } from "antd"
import { useState } from "react"
import style from "./style.module.scss"
import moment from "moment/moment"
import "react-loading-skeleton/dist/skeleton.css"
import { connect } from "react-redux"
import { getUser } from "../../pages/Management/redux/action"
const ManagementTable = props => {
  const { tableData, loading, getUser } = props

  const info = []
  tableData &&
    tableData.results?.map((item, index) => {
      const date = moment(item.date_joined).format("DD MMM YYYY")
      return info.push({
        key: index + 1,
        No: index + 1,
        ID: item.id,
        UserName: `${item.first_name} ${item.last_name}`,
        EmailID: item.email,
        University: item.university ? item.university.name : "--",
        universityId: item?.university?.id,
        Status: item.user_active_status ? "Active" : "Inactive",
        CreatedOn: date,
        Sports: item.sports ? item.sports.name : "--",
        sportsId: item.sports?.id,
        first_name: item.first_name,
        last_name: item.last_name,
        dob: item.dob ? moment(item.dob) : "",
        city: item.city,
        zipCode: item.zip_code,
        state: item.state,
        streetAddress: item.street_address,
        gender: item.gender,
        bio: item.bio,
        phoneNumber: item.phone_number
      })
    })

  //   const paginationConfig = {
  //   // position: ["Center"], // Specify the position of the pagination
  //   showSizeChanger: true, // Display options to change the page size
  //   // showQuickJumper: true, // Display quick jumper input
  //   pageSizeOptions: ["10", "20", "30"], // Available page size options
  //   defaultPageSize: 15, // Default page size
  //   total: info.length // Total number of items
  // }
  const paginationConfig = {
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30"],
    // defaultPageSize: 10,
    total: tableData.total_objects,
    current: tableData.current_page,
    pageSize: tableData.page_size,
    onChange: (page, pageSize) => {
      let query = ""

      if (page) {
        query += `?page=${page}`
      }

      if (pageSize) {
        if (query.length > 0) {
          query += `&no_of_pages=${pageSize}`
        } else {
          query += `?no_of_pages=${pageSize}`
        }
      }

      getUser(query)
    }
  }

  return (
    <div className={style.tableSection}>
      <Table
        className={style.teamemberTable}
        columns={props.columns}
        dataSource={info}
        pagination={paginationConfig}
      />
    </div>
  )
}
const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
  getUser: data => dispatch(getUser(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(ManagementTable)
