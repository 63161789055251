// @ts-nocheck
import React, { useState, useEffect } from "react"
import { Col, Row, Input, Select } from "antd"
import style from "./style.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { PlusOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons"
import CreateContentDrawer from "../CreateContentDrawer/index"
import ContentManagementDetail from "../ContentManagementDetail"
import CommonButton from "../../../common/button"
import settingIcon from "../../../assets/Images/setting.svg"
import moreIcon from "../../../assets/Images/more.svg"
import { contentLablesStyle } from "../../../common/contentLablesStyle"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import {
  getContentManagement,
  getContentManagementDetail
} from "../redux/action"

const ContentList = ({ uniValue, changeUni }) => {
  const [contentItems, setContentItems] = useState([])
  const [CategoryData, setCategory] = useState("")
  const [selectCategory, setSelectCategory] = useState()
  const [selectUniversty, setSelectUniversty] = useState(uniValue)
  const [search, SetSearch] = useState("")
  const [reset, SetReset] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDetail, setOpenDetail] = useState(false)
  const [updateDrawer, setUpdateDrawer] = useState({
    isUpdate: false,
    isUpdateData: {}
  })

  const dispatch = useDispatch()

  const { contentManagementData, contentManagementSingleData, loader } =
    useSelector(state => state.ContentManagement)

  useEffect(() => {
    const stateObject = {
      selectCategory,
      selectUniversty,
      search
    }

    if (selectCategory || selectUniversty || search) {
      dispatch(getContentManagement(stateObject))
    }
    if (selectCategory || search) {
      SetReset(true)
    }
  }, [selectCategory, selectUniversty, search])

  useEffect(() => {
    setContentItems(contentManagementData?.posts)
  }, [contentManagementData])

  const openContentDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
    setUpdateDrawer({
      isUpdate: false,
      isUpdateData: {}
    })
  }

  const handleDetailClose = () => {
    setOpenDetail(false)
  }

  const openContentDetailDrawer = id => {
    dispatch(getContentManagementDetail(id))
    setOpenDetail(true)
  }

  const handleChangegetCategory = (value, label) => {
    setSelectCategory(value)
  }

  const onUpdateDrawer = value => {
    setOpenDetail(false)
    setOpen(true)
    setUpdateDrawer({
      isUpdate: true,
      isUpdateData: value
    })
  }
  const { category } = useSelector(state => state.ContentManagement)

  useEffect(() => {
    if (category) {
      const category_array = []
      category?.map((a, b) => {
        category_array.push({ value: a.id, label: a.name })
      })
      setCategory(category_array)
    }
  }, [category])

  const handleSearch = e => {
    const inputData = e.target.value
    SetSearch(inputData)
  }

  const emptyStates = () => {
    setSelectCategory()
    SetSearch("")
  }
  const handleResetButtonClick = () => {
    emptyStates()
    dispatch(getContentManagement({ selectUniversty }))
    SetReset(false)
  }

  const ContentDataStatus = [
    { status: "To Do" },
    { status: "In Progress" },
    { status: "Needs Review" },
    { status: "Scheduled" },
    { status: "Complete" }
  ]

  const FilterStatus = (item, status) => {
    return item == status
  }

  const ContentDataLoader = () =>
    [1, 2].map(() => {
      return (
        <div className={style.skeletonContainer}>
          <Skeleton
            baseColor="#1F2732"
            highlightColor="#121617"
            width={150}
            height={25}
          />
          <div className={style.lables}>
            <Skeleton baseColor="#1F2732" highlightColor="#121617" width={60} />
            <Skeleton baseColor="#1F2732" highlightColor="#121617" width={60} />
            <Skeleton baseColor="#1F2732" highlightColor="#121617" width={60} />
          </div>
          <Skeleton baseColor="#1F2732" highlightColor="#121617" height={125} />
          <div className={style.description}>
            <Skeleton
              baseColor="#1F2732"
              highlightColor="#121617"
              height={10}
            />
          </div>
          <div className={style.usersContainer}>
            <div className={style.commits}>
            </div>
            <div className={style.user}>
              <Skeleton
                baseColor="#1F2732"
                highlightColor="#121617"
                circle
                height={38}
                width={38}
              />
              <Skeleton
                baseColor="#1F2732"
                highlightColor="#121617"
                circle
                height={38}
                width={38}
              />
              <Skeleton
                baseColor="#1F2732"
                highlightColor="#121617"
                circle
                height={38}
                width={38}
              />
            </div>
          </div>
        </div>
      )
    })

    console.log("ContentDataStatus",ContentDataStatus)

  return (
    <div className={style.mainWrapper}>
      <ContentManagementDetail
        open={openDetail}
        onClose={handleDetailClose}
        detailData={contentManagementSingleData}
        onUpdateDrawer={onUpdateDrawer}
        uniValue={uniValue}
      />
      <CreateContentDrawer
        onClose={onClose}
        open={open}
        uniValue={uniValue}
        updateDrawer={updateDrawer}
      />
      <div className={style.contentTopBar}>
        <div className={style.uniDataContainer}>
          <h1>{contentManagementData?.university?.name || "---"}</h1>
          <h2 onClick={() => changeUni(false)}>
            Change university <RightOutlined />
          </h2>
        </div>
        <div className={style.filterside}>

          <div className={style.inputBtnContainer}>
            <Input
              className={style.searchInput}
              placeholder="Search"
              prefix={<SearchOutlined />}
              onChange={handleSearch}
              value={search}
            />
            <CommonButton
              title="Add Content"
              onBtnClick={openContentDrawer}
              icon={<PlusOutlined />}
              type="dark"
              customClass={style.addBtnStyle}
            />
            {reset && (
              <CommonButton
                title="Clear"
                onBtnClick={handleResetButtonClick}
                type="dark"
                customClass={style.addBtnStyle}
              />
            )}
          </div>
          <div className={style.groupByContainer}>
            <h1>Category by</h1>
            <Select
              className={style.selectorStyle}
              placeholder="Select"
              onChange={handleChangegetCategory}
              popupClassName={style.selectItemstyle}
              options={CategoryData && CategoryData}
              value={selectCategory}
            />
          </div>
        </div>
      </div>

      <div className={style.contentCardContainer}>
        {ContentDataStatus?.map((item, index) => {
          return (
            <div key={index} className={style.contentCardInnerContainer}>
              <div className={style.allCardContainer}>
                <div className={style.cardTitleContainer}>
                  <div className={style.cardTitleStyle}>
                    <h1>{item?.status}</h1>
                    <div className={style.itemsNo}>
                      {(contentItems &&
                        contentItems?.filter(e =>
                          FilterStatus(e?.status?.name, item?.status)
                        )?.length) ||
                        0}
                    </div>
                  </div>
                  <div className={style.moreIconContainer}>
                    {/* <img src={moreIcon} /> */}
                  </div>
                </div>
                <div className={style.cardsContainer}>
                  {loader ? (
                    <ContentDataLoader />
                  ) : (
                    contentItems &&
                    contentItems
                      ?.filter(e => FilterStatus(e?.status?.name, item?.status))
                      ?.map((item, index) => {
                        const GetFileAttachment = item?.thumbnail
                        const LablesData = item?.labels
                        const AssigneeList = item?.user
                        const AssigneeDisplayList = AssigneeList.slice(0, 3)
                        const AssigneeTotalList =
                          AssigneeList?.length - AssigneeDisplayList?.length
                        return (
                          <div
                            key={index}
                            className={style.cardItemsContainer}
                            onClick={() => openContentDetailDrawer(item?.id)}
                          >
                            <div className={style.cardItemContainer}>
                              <div className={style.cardTitleStyle}>
                                <h1>{item?.title || "---"}</h1>
                              </div>
                              <div className={style.moreIconContainer}>
                                {/* <img src={moreIcon} /> */}
                              </div>
                            </div>
                            <div className={style.labelDateContainer}>
                              {LablesData?.map((labelItem, labelIndex) => {
                                return (
                                  <div
                                    key={labelIndex}
                                    className={style.labelStyle}
                                    style={{
                                      background: contentLablesStyle?.find(
                                        e => e?.name == labelItem?.name
                                      )?.background,
                                      color: contentLablesStyle?.find(
                                        e => e?.name == labelItem?.name
                                      )?.color
                                    }}
                                  >
                                    {labelItem?.name}
                                  </div>
                                )
                              })}
                            </div>
                            {GetFileAttachment && (
                              <div className={style.imgContainer}>
                                <img
                                  src={GetFileAttachment}
                                  className={style.imgStyle}
                                />
                              </div>
                            )}
                            {item?.description ? (
                              <p
                                className={style.descriptionStyle}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item?.description.length > 50
                                      ? item?.description
                                        .split(" ")
                                        .slice(0, 50)
                                        .join(" ") + "..."
                                      : item?.description
                                }}
                              />
                            ) : (
                              <p>----</p>
                            )}
                            <Row>
                              <Col
                                lg={14}
                                className={style.repostMesgContainer}
                              >
                              </Col>
                              <Col lg={10}>
                                <div className={style.assigneeStyle}>
                                  {AssigneeDisplayList?.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={style.assigneeItemStyle}
                                      >
                                        {item?.picture ? (
                                          <img
                                            src={item?.picture}
                                            className={style.imgStyle}
                                          />
                                        ) : (
                                          <>
                                            {item?.first_name
                                              ? item?.first_name.slice(0, 1)
                                              : "F"}
                                            {item?.last_name
                                              ? item?.last_name.slice(0, 1)
                                              : "L"}
                                          </>
                                        )}
                                      </div>
                                    )
                                  })}
                                  {AssigneeTotalList > 0 && (
                                    <div className={style.assigneeItemStyle}>
                                      +{AssigneeTotalList}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )
                      })
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ContentList
