import { GET_USER, GET_USER_SUCCESS, GET_USER_FAIL, CREATE_USER, CREATE_USER_SUCCESS, CREATE_USER_FAIL, DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAILURE, UPDATE_USER_SUCCESS, UPDATE_USER, UPDATE_USER_FAILURE } from "./actionTypes"

const initialState = {
  data: false,
  loader: false,
  createUserloader: false,
  updateUserloader: false,
  deleteUserloader: false,
  error: false,
  deleteUser: false,
  deleteUserFailure: false,
  updateuser: false,
  updateUserFailure: false,

}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        loader: true
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        data: action.data,
        loader: false
      }
    case GET_USER_FAIL:
      return {
        ...state,
        error: action.error,
        loader: false
      }
    case CREATE_USER:
      return {
        ...state,
        createUserloader: true
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        data: action.data,
        createUserloader: false
      }
    case CREATE_USER_FAIL:
      return {
        ...state,
        error: action.error,
        createUserloader: false
      }
    case DELETE_USER:
      return {
        ...state,
        deleteUserloader: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserloader: false,
        deleteUser: action.data,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        deleteUserloader: false,
        deleteUserFailure: action.error,
      };
    case UPDATE_USER:
      return {
        ...state,
        updateUserloader: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserloader: false,
        updateuser: action.data,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUserloader: false,
        updateUserFailure: action.error,
      };
    default:
      return state
  }
}
