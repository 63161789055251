import {
  GET_CONTENT_MANAGEMENT,
  GET_CONTENT_MANAGEMENT_SUCCESS,
  GET_CONTENT_MANAGEMENT_FAILURE,
  GET_LABELS,
  GET_LABELS_SUCCESS,
  GET_LABELS_FAILURE,
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILURE,
  GET_UNIVERSITY,
  GET_UNIVERSITY_SUCCESS,
  GET_UNIVERSITY_FAILURE,
  GET_SPORTS,
  GET_SPORTS_SUCCESS,
  GET_SPORTS_FAILURE,
  GET_SOCIAL_MEDIA_SHARE,
  GET_SOCIAL_MEDIA_SHARE_SUCCESS,
  GET_SOCIAL_MEDIA_SHARE_FAILURE,
  GET_CONTENT_MANAGEMENT_DETAIL,
  GET_CONTENT_MANAGEMENT_SUCCESS_DETAIL,
  GET_CONTENT_MANAGEMENT_FAILURE_DETAIL,
  POST_NEW_CONTANT,
  POST_NEW_CONTANT_SUCCESS,
  POST_NEW_CONTANT_FAILURE,
  PATCH_CONTANT_REQUEST,
  PATCH_CONTANT_REQUEST_SUCCESS,
  PATCH_CONTANT_REQUEST_FAILURE,
  DELETE_CONTANT_REQUEST,
  DELETE_CONTANT_REQUEST_SUCCESS,
  DELETE_CONTANT_REQUEST_FAILURE,
  GET_POPULAR_UNI,
  GET_POPULAR_UNI_FAILURE,
  GET_POPULAR_UNI_SUCCESS,
  GET_STATUS,
  GET_STATUS_SUCCESS,
  GET_STATUS_FAILURE,
  GET_STATE,
  GET_STATE_SUCCESS,
  GET_STATE_FAILURE
} from "./actionTypes"

const initialState = {
  contentManagementData: [],
  loader: false,
  error: "",

  contentManagementSingleData: [],
  singleDataloader: false,
  singleDataerror: "",

  lables: [],
  lablesLoader: false,
  labelsError: "",

  category: [],
  categoryLoader: false,
  categoryError: "",

  popularUni: [],
  popularUniLoader: false,
  popularUniError: "",

  university: [],
  universityLoader: false,
  universityError: "",

  sports: [],
  sportsLoader: false,
  sportsError: "",

  socialMedia: [],
  socialMediaLoader: false,
  socialMediaError: "",

  postNewContant: [],
  postNewContantLoader: false,
  postNewContantError: false,

  patchContant: false,
  patchContantLoad: false,
  patchContantError: false,

  deleteContant: false,
  deleteContantLoad: false,
  deleteContantError: false,

  getStatusData: [],
  getStatusLoad: false,
  getStatusError: false,

  getStateData: [],
  getStateLoad: false,
  getStateError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTENT_MANAGEMENT:
      return {
        ...state,
        loader: true
      }
    case GET_CONTENT_MANAGEMENT_SUCCESS:
      return {
        ...state,
        contentManagementData: action.data,
        loader: false
      }
    case GET_CONTENT_MANAGEMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        loader: false
      }
    case GET_CONTENT_MANAGEMENT_DETAIL:
      return {
        ...state,
        singleDataloader: true
      }
    case GET_CONTENT_MANAGEMENT_SUCCESS_DETAIL:
      return {
        ...state,
        contentManagementSingleData: action.data,
        singleDataloader: false
      }
    case GET_CONTENT_MANAGEMENT_FAILURE_DETAIL:
      return {
        ...state,
        singleDataerror: action.error,
        singleDataloader: false
      }
    case GET_LABELS:
      return {
        ...state,
        lablesLoader: true
      }
    case GET_LABELS_SUCCESS:
      return {
        ...state,
        lables: action.data,
        lablesLoader: false
      }
    case GET_LABELS_FAILURE:
      return {
        ...state,
        labelsError: action.error,
        lablesLoader: false
      }
    case GET_CATEGORY:
      return {
        ...state,
        categoryLoader: true
      }
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.data,
        categoryLoader: false
      }
    case GET_CATEGORY_FAILURE:
      return {
        ...state,
        categoryError: action.error,
        categoryLoader: false
      }

    case GET_POPULAR_UNI:
      return {
        ...state,
        popularUniLoader: true
      }
    case GET_POPULAR_UNI_SUCCESS:
      return {
        ...state,
        popularUni: action.data,
        popularUniLoader: false
      }
    case GET_POPULAR_UNI_FAILURE:
      return {
        ...state,
        popularUniError: action.error,
        popularUniLoader: false
      }

    case GET_UNIVERSITY:
      return {
        ...state,
        universityLoader: true
      }
    case GET_UNIVERSITY_SUCCESS:
      return {
        ...state,
        university: action.data,
        universityLoader: false
      }
    case GET_UNIVERSITY_FAILURE:
      return {
        ...state,
        universityError: action.error,
        universityLoader: false
      }
    case GET_SPORTS:
      return {
        ...state,
        sportsLoader: true
      }
    case GET_SPORTS_SUCCESS:
      return {
        ...state,
        sports: action.data,
        sportsLoader: false
      }
    case GET_SPORTS_FAILURE:
      return {
        ...state,
        sportsError: action.error,
        sportsLoader: false
      }
    case GET_SOCIAL_MEDIA_SHARE:
      return {
        ...state,
        socialMediaLoader: true
      }
    case GET_SOCIAL_MEDIA_SHARE_SUCCESS:
      return {
        ...state,
        socialMedia: action.data,
        socialMediaLoader: false
      }
    case GET_SOCIAL_MEDIA_SHARE_FAILURE:
      return {
        ...state,
        socialMediaError: action.error,
        socialMediaLoader: false
      }

    case POST_NEW_CONTANT:
      return {
        ...state,
        postNewContantLoader: true
      }
    case POST_NEW_CONTANT_SUCCESS:
      return {
        ...state,
        postNewContant: action.payload,
        postNewContantLoader: false
      }
    case POST_NEW_CONTANT_FAILURE:
      return {
        ...state,
        postNewContantError: action.error,
        postNewContantLoader: false
      }
    case PATCH_CONTANT_REQUEST:
      return {
        ...state,
        patchContantLoad: true
      }
    case PATCH_CONTANT_REQUEST_SUCCESS:
      return {
        ...state,
        patchContant: action.data,
        patchContantLoad: false
      }
    case PATCH_CONTANT_REQUEST_FAILURE:
      return {
        ...state,
        patchContantError: action.data,
        patchContantLoad: false
      }
    case DELETE_CONTANT_REQUEST:
      return {
        ...state,
        deleteContantLoad: true
      }
    case DELETE_CONTANT_REQUEST_SUCCESS:
      return {
        ...state,
        deleteContant: action.data,
        deleteContantLoad: false
      }
    case DELETE_CONTANT_REQUEST_FAILURE:
      return {
        ...state,
        deleteContantError: action.data,
        deleteContantLoad: false
      }


    case GET_STATUS:
      return {
        ...state,
        getStatusLoad: true
      }
    case GET_STATUS_SUCCESS:
      return {
        ...state,
        getStatusData: action.data,
        getStatusLoad: false
      }
    case GET_STATUS_FAILURE:
      return {
        ...state,
        getStatusError: action.data,
        getStatusLoad: false
      }

      case GET_STATE:
      return {
        ...state,
        getStateLoad: true
      }
    case GET_STATE_SUCCESS:
      return {
        ...state,
        getStateData: action.data,
        getStateLoad: false
      }
    case GET_STATE_FAILURE:
      return {
        ...state,
        getStateError: action.data,
        getStateLoad: false
      }
    default:
      return state
  }
}
