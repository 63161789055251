import {
  ALL_TRANSACTIONS,
  ALL_TRANSACTIONS_SUCCESS,
  ALL_TRANSACTIONS_FAILURE
} from "./type"

export const getAlltransactions = data => ({
  type: ALL_TRANSACTIONS,
  data
})

export const getAlltransactionsSuccess = data => ({
  type: ALL_TRANSACTIONS_SUCCESS,
  data
})

export const getAlltransactionsFaliure = data => ({
  type: ALL_TRANSACTIONS_FAILURE,
  data
})
