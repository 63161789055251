import React from "react"

import { BellOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons"
import style from "./style.module.scss"
import menu from "../../assets/menu.png"
import { useDispatch, useSelector } from "react-redux"
import { toggelAction } from "../../pages/Dashboard/redux/actions"

const Header = ({
  heading,
  subHeading
}) => {

  const dispatch = useDispatch()

  const { toggelSidebar } = useSelector(state => state.Dashboard)

  return (
    <div className={style.headerSection}>
      <div className={style.HeadingBlock}>
        <img alt="menu" src={menu} className={style.menuIcon} onClick={() => dispatch(toggelAction(!toggelSidebar))} />
        <h2 className={style.mainHeading}>{heading}</h2>
        {subHeading && <RightOutlined style={{ color: "#fff", fontSize: 12 }} />}
        <p className={style.subHeading}>{subHeading}</p>
      </div>
      <div className={style.rightSide}>
        <SearchOutlined style={{ color: "#898F96", fontSize: 16 }} />
        <BellOutlined style={{ color: "#FFFF", fontSize: 24 }} />
        <div className={style.avatar}>
          <div className={style.profile}>sd</div>
        </div>
      </div>
    </div>
  )
}

export default Header
