import React from 'react'
import style from './style.module.scss'
import { Table } from 'antd'
import moment from 'moment'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const SalesTable = ({ tableData, setLimit, setOffset, loading }) => {

    const GetstatusColor = [
        {
            name: "new",
            background: "#59b4d1"
        },
        {
            name: "approved",
            background: "#65c5c2"
        },
        {
            name: "rejected",
            background: "#F54336"
        },
    ]

    const SalesColumns = [
        {
            title: 'Date',
            dataIndex: 'created',
            render: (date) => {
                return moment(date).format("DD MMM YYYY")
            },
        },
        {
            title: 'Order',
            dataIndex: 'number',
        },
        {
            title: 'Amount',
            dataIndex: 'subtotal',
            render: (amount) => {
                return `$${amount}`
            }
        },
        {
            title: 'Affiliate',
            dataIndex: 'affiliate',
            render: (affiliate) => {
                return affiliate?.name
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
                return (
                    <div
                        style={{ background: GetstatusColor?.find((e) => e.name == status)?.background }}
                        className={style.statusStyle}
                    >{status}</div>
                )
            }
        },
        {
            title: 'Order Total',
            dataIndex: 'total',
            render: (total) => {
                return `$${total}`
            }
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            render: (total) => {
                return `$${total}`
            }
        },
    ];

    const paginationConfig = {
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30"],
        defaultPageSize: 10,

        total: Number(tableData?.count),
        pageSize: Number(tableData?.limit),
        onChange: (page, pageSize) => {
            const getPage = page - 1
            setOffset(getPage * Number(pageSize))
            setLimit(Number(pageSize))
        },
    };

    return (
        <div className={style.salesTableContainer}>
            {loading ? <Table
                rowKey="key"
                pagination={false}
                dataSource={[...Array(5)].map((_, index) => ({
                    key: `key${index}`
                }))}
                columns={SalesColumns.map(column => {
                    return {
                        ...column,
                        render: function renderPlaceholder() {
                            return (
                                <>
                                    <SkeletonTheme baseColor="#1f2732" highlightColor="#5c6f87">
                                        <Skeleton width="70%" height={10} />
                                    </SkeletonTheme>
                                </>
                            )
                        }
                    }
                })}
            />
                :
                <Table
                    columns={SalesColumns}
                    dataSource={tableData?.orders}
                    pagination={paginationConfig}
                />
            }

        </div>
    )
}

export default SalesTable
