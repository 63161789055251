// @ts-nocheck
import React from "react"
import HomeLayout from "../../layout/HomeLayout"
import PubNub from "pubnub"
import { PubNubProvider } from "pubnub-react"
import SupportChat from "./Chat"

const SupportMessages = () => {
  const pubnub = new PubNub({
    publishKey: "pub-c-1937d998-7c68-43f6-94dd-f104c1dd6377",
    subscribeKey: "sub-c-c0ef38ed-d4e6-4fdb-ad08-96badb0b5504",
    userId: "admin_01"
  })

  return (
    <>
      <HomeLayout heading={'Support Message'}>
        <PubNubProvider client={pubnub}>
          <SupportChat />
        </PubNubProvider>
      </HomeLayout>
    </>
  )
}
export default SupportMessages
