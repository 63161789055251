import {
    GET_HELP_CENTER,
    GET_HELP_CENTER_FAIL,
    GET_HELP_CENTER_SUCCESS,
    PATCH_HELP_CENTER,
    PATCH_HELP_CENTER_FAIL,
    PATCH_HELP_CENTER_SUCCESS
} from "./actionTypes"

export const getHelpCenter = (data) => ({
    type: GET_HELP_CENTER,
    data
})

export const getHelpCenterSuccess = data => ({
    type: GET_HELP_CENTER_SUCCESS,
    data
})

export const getHelpCenterFailure = data => ({
    type: GET_HELP_CENTER_FAIL,
    data
})

export const patchHelpCenter = (id, data, success) => ({
    type: PATCH_HELP_CENTER,
    id,
    data,
    success
})

export const patchHelpCenterSuccess = data => ({
    type: PATCH_HELP_CENTER_SUCCESS,
    data
})

export const patchHelpCenterFailure = data => ({
    type: PATCH_HELP_CENTER_FAIL,
    data
})

