import XHR from "../../../utils/XHR"
import { BASE_URL } from "../../../config/app"
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    affiliateUserFailure,
    affiliateUserSuccess,
    getSalesListFailure,
    getSalesListSuccess
} from "./action";
import { AFFILIATE_USER, GET_Sales_LIST } from "./actionTypes";

// GET Sales Management

async function getSalesManagementSaga(data, id, minDate, maxdate, limit, offset) {
    const affiliate_id = id ? `affiliate_id=${id}&` : ""
    const searchOrder = data ? `search=${data}&` : ""
    const startDate = minDate && maxdate ? `created_at_min=${minDate}&` : ""
    const endDate = minDate && maxdate ? `created_at_max=${maxdate}&` : ""
    let URL = `${BASE_URL}/api/v1/sales/?${affiliate_id}${searchOrder}${startDate}${endDate}limit=${limit}&offset=${offset}`

    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
        },
        method: "GET"
    }
    return XHR(URL, options)
}


// GET filter Order

async function affiliateUsersSaga(data) {
    const searchUser = data ? `?search=${data}` : ""
    let URL = `${BASE_URL}/api/v1/affiliates/${searchUser}`;

    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
        },
        method: "GET",
    }
    return XHR(URL, options)
}



function* salesManagementData({ data, id, minDate, maxDate, limit, offset }) {
    try {
        const response = yield call(getSalesManagementSaga, data, id, minDate, maxDate, limit, offset)
        yield put(getSalesListSuccess(response.data))
    } catch (error) {
        yield put(getSalesListFailure(error.response))
    }
}



function* affiliateUsers({ data }) {
    try {
        const response = yield call(affiliateUsersSaga, data)
        yield put(affiliateUserSuccess(response.data))
    } catch (error) {
        yield put(affiliateUserFailure(error.response))
    }
}


export default all([
    takeLatest(GET_Sales_LIST, salesManagementData),
    takeLatest(AFFILIATE_USER, affiliateUsers),
])