import { Images } from "./themes/sidebarImg"

export const routes = [
  {
    name: "Dashboard",
    path: "/",
    iconSrc: Images.dashboard,
    activeIconSrc: Images.dashboardActive
  },
  {
    name: "User Management",
    path: "/user-management",
    iconSrc: Images.users,
    activeIconSrc: Images.managementActive
  },
  {
    name: "Payout Management",
    path: "/payout-management",
    iconSrc: Images.card,
    activeIconSrc: Images.cardActive
  },
  {
    name: "Sales Management",
    path: "/sales-management",
    iconSrc: Images.card,
    activeIconSrc: Images.cardActive
  },
  {
    name: "Content Management",
    path: "/content-management",
    iconSrc: Images.Mask,
    activeIconSrc: Images.MaskActive
  },
  // {
  //   name: "Analytics",
  //   path: "/analytics",
  //   iconSrc: Images.analytics,
  //   activeIconSrc: Images.analyticsActive
  // },
  // {
  //   name: "Messages",
  //   path: "/messages",
  //   iconSrc: Images.messages,
  //   activeIconSrc: Images.messagesActive
  // },
  // {
  //   name: "Mail",
  //   path: "/mail",
  //   iconSrc: Images.mail,
  //   activeIconSrc: Images.mailActive
  // },
  {
    name: "Help Center",
    path: "/help-center",
    iconSrc: Images.support,
    activeIconSrc: Images.supportActive
  },
  {
    name: "Support Messages",
    path: "/message",
    iconSrc: Images.chatMessage,
    activeIconSrc: Images.chatMessageActive
  },
  // {
  //   name: "Profile",
  //   path: "/profile",
  //   iconSrc: Images.profileIcon,
  //   activeIconSrc: Images.profileIconActive,
  //   adminTitle: true
  // },
  {
    name: "Settings",
    path: "/settings",
    iconSrc: Images.setting,
    activeIconSrc: Images.settingActive,
    adminTitle: true
  }
]
