import { all, takeLatest, put, call } from "redux-saga/effects"
import { POST_LOGIN } from "./actionTypes"
import { postLoginSuccess, postLoginFail, setAccessToken } from "./action"
import XHR from "../../../utils/XHR"
import { BASE_URL } from "../../../config/app"
import { toast } from "react-hot-toast"

async function getLoginApi(data) {
  const URL = `${BASE_URL}/api/v1/admin/login/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* onPostLoginData({ data, navigate }) {
  try {
    const response = yield call(getLoginApi, data)
    yield put(postLoginSuccess(response.data))
    localStorage.setItem("token", response.data.token)
    yield put(setAccessToken(response.data.token))
    navigate("/")
  } catch (error) {
    const { response } = error
    yield put(postLoginFail(response.data))
    if (response.data.non_field_errors) {
      toast.error(response.data.non_field_errors)
    } else if (response.data.msg) {
      toast.error(response.data.msg)
    }
  }
}

export default all([takeLatest(POST_LOGIN, onPostLoginData)])
