import {
    GET_DASHBOARD_DATA,
    GET_DASHBOARD_DATA_FAIL,
    GET_DASHBOARD_DATA_SUCCESS,
    TOGGEL_SIDEBAR
} from "./actionTypes"

export const getDashboardData = (data) => ({
    type: GET_DASHBOARD_DATA,
    data
})

export const getDashboardDataSuccess = data => ({
    type: GET_DASHBOARD_DATA_SUCCESS,
    data
})

export const getDashboardDataFail = data => ({
    type: GET_DASHBOARD_DATA_FAIL,
    data
})

export const toggelAction = data => ({
    type: TOGGEL_SIDEBAR,
    data
})