// @ts-nocheck
import { CloseOutlined, RightOutlined } from '@ant-design/icons'
import { Drawer, Input } from 'antd'
import React from 'react'
import style from './style.module.scss'
import CommonButton from './../../../../../web/src/common/button'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { patchHelpCenter } from '../redux/action'

const ReplayDrawer = ({ open, handleClose, email, id, loader }) => {

    const [replayValue, setReplayValue] = useState("")
    const dispatch = useDispatch()

    const { TextArea } = Input;

    const onSuccessHandle = () => {
        setReplayValue("")
        handleClose()
    }

    return (
        <div>
            <Drawer
                className={style.contentDrawer}
                title={
                    <div className={style.topTitleDrawer}>
                        <h4>
                            {email || "--"}
                            <RightOutlined />
                            <span className={style.activelink}>
                                Reply
                            </span>
                        </h4>
                        <div className={style.right}>
                            <span onClick={handleClose}>
                                <CloseOutlined />
                            </span>
                        </div>
                    </div>
                }
                closeIcon={false}
                placement="right"
                onClose={handleClose}
                open={open}
                width={800}
                footer={<></>}
            >
                <div className={style.drawerBodyContainer}>
                    <TextArea
                        rows={8}
                        placeholder="Reply"
                        value={replayValue}
                        onChange={(e) => setReplayValue(e.target.value)}
                    />
                    <CommonButton
                        title="Reply"
                        disabled={replayValue?.length != "" ? false : true}
                        type="dark"
                        customClass={style.replyStyle}
                        loading={loader}
                        onBtnClick={() => {
                            dispatch(patchHelpCenter(id, { reply: replayValue, is_replyed: true }, onSuccessHandle))
                        }}
                    />
                </div>
            </Drawer>
        </div>
    )
}

export default ReplayDrawer