// @ts-nocheck
import React, { useEffect, useState } from "react"
import style from "./style.module.scss"
import { Col, Drawer, Input, Row, Select, DatePicker } from "antd"
import { CloseOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons"
import CommonButton from "../../../common/button"
import CommonInput from "../../../common/input"
import simpleArrowIcon from "../../../assets/Images/simpleDownErrow.png"
import arrowIcon from "../../../assets/Images/Arrow-Down.svg"
import moment from "moment"

import { createUser, editUser } from "../redux/action"
import { connect, useDispatch } from "react-redux"
import { getUniversity, getSports, getPostState } from "../../ContentManagement/redux/action"
import { useSelector } from "react-redux"

const CreateUserDrawer = props => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")

  const [firstNameError, setFirstNameError] = useState("")
  const [lastNameError, SetLastNameError] = useState("")
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberError, setPhoneNumberError] = useState("")
  const [universityData, setUniversity] = useState([])
  const [selectUniversity, setSelectedUniversity] = useState([])
  const [selectUniversityError, setSelectedUniversityError] = useState("")
  const [sportsData, setSports] = useState([])
  const [selectSports, setSelectSports] = useState([])
  const [selectSportsError, setSelectSportsError] = useState("")
  const [streetAddress, setStreetAddress] = useState("")
  const [dateOfBirth, setDateOfBirth] = useState("")
  const [selectGender, setSelectedGender] = useState(null)
  const [zipCode, setZipCode] = useState("")
  const [bio, setBio] = useState("")
  const [statusData, setStatus] = useState([])
  const [selectedState, setSelectedState] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const [isActive, setIsActive] = useState(false)
  const [isDeActive, setIsDeActive] = useState(false)

  const {
    createUser,
    open,
    onClose,
    userUpdateDrawer,
    requesting,
    getUniversity,
    getSports,
    createUserError,
    editUser,
    updateUserloader,
    getStates
  } = props

  const { university, sports, getStateData } = useSelector(state => state.ContentManagement)

  const { isUpdate, isUpdateData } = userUpdateDrawer

  const handelChangeGender = (value, label) => {
    setSelectedGender(value)
  }
  const handelChangeState = (value, label) => {
    setSelectedState(value)
  }
  const handelChangeCity = (e) => {
    setSelectedCity(e.target.value)
  }

  const handleChangeUniversity = (value, label) => {
    setSelectedUniversity(value)
    setSelectedUniversityError("")
  }
  const handleChangeSport = (value, label) => {
    setSelectSports(value)
    setSelectSportsError("")
  }

  useEffect(() => {
    if (isUpdate) {
      setFirstName(isUpdateData?.first_name || "")
      setLastName(isUpdateData?.last_name || "")
      // setPhoneNumber(
      //   isUpdateData?.phoneNumber == undefined
      //     ? ""
      //     : isUpdateData?.phoneNumber || ""
      // )
      setEmail(isUpdateData?.EmailID || "")
      setSelectSports(isUpdateData?.sportsId || null)
      setSelectedUniversity(isUpdateData?.universityId || null)
      setStreetAddress(isUpdateData?.streetAddress || "")
      setSelectedGender(isUpdateData?.gender || null)
      setDateOfBirth(isUpdateData?.dob || "")
      setZipCode(isUpdateData?.zipCode || "")
      setBio(isUpdateData?.bio || "")
      setSelectedState(isUpdateData?.state || null)
      setSelectedCity(isUpdateData?.city || null)
      setIsActive((isUpdateData?.Status == "Active" && true) || false)
      setIsDeActive((isUpdateData?.Status == "Inactive" && true) || false)
    }
  }, [isUpdate])

  const onSubmit = () => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      // phone_number: phoneNumber,
      email: email,
      university: selectUniversity,
      sports: selectSports
    }
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      // phoneNumber.trim() === "" ||
      email.trim() === "" ||
      selectUniversity?.length === 0 ||
      selectSports?.length === 0
    ) {
      setFirstNameError("First name is required.")
      SetLastNameError("Last name is required.")
      // setPhoneNumberError("Phone number is required.")
      setEmailError("Email is required.")
      setSelectedUniversityError("This is required.")
      setSelectSportsError("This is required.")
    } else {
      createUser(data, onClose)
    }
  }

  const onUpdate = () => {
    const data = {
      id: isUpdateData?.ID,
      body: {
        first_name: firstName,
        last_name: lastName,
        university: selectUniversity,
        sports: selectSports,
        zip_code: zipCode,
        state: selectedState,
        city: selectedCity,
        street_address: streetAddress,
        gender: selectGender,
        bio: bio,
        // phone_number: phoneNumber,
        ...(!!dateOfBirth && { dob: moment(dateOfBirth).format("YYYY-MM-DD") }),
        ...(isUpdateData?.EmailID !== email && { email: email }),
        user_active_status: isActive ? true : false
      }
    }
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      // phoneNumber.trim() === "" ||
      email.trim() === "" ||
      selectUniversity?.length === 0 ||
      selectSports?.length === 0
    ) {
      setFirstNameError("First name is required.")
      SetLastNameError("Last name is required.")
      // setPhoneNumberError("Phone number is required.")
      setEmailError("Email is required.")
      setSelectedUniversityError("This is required.")
      setSelectSportsError("This is required.")
    } else {
      editUser(data, onClose)
    }
  }

  const onDateChange = date => {
    setDateOfBirth(date)
  }
  useEffect(() => {
    if (!open) {
      setFirstName("")
      setLastName("")
      // setPhoneNumber("")
      setEmail("")
      setSelectSports([])
      setSelectedUniversity([])
      setStreetAddress("")
      setSelectedGender("")
      setFirstNameError("")
      SetLastNameError("")
      // setPhoneNumberError("")
      setEmailError("")
      setSelectSportsError("")
      setSelectedUniversityError("")
    }
    if (open) {
      getUniversity()
      getSports()
      getStates()
    }
  }, [open])

  useEffect(() => {
    if (university) {
      const university_array = []
      university?.map((a, b) => {
        university_array?.push({ value: a?.id, label: a?.name })
      })
      setUniversity(university_array)
    }
    if (sports) {
      const sports_array = []
      sports?.map((a, b) => {
        sports_array.push({ value: a.id, label: a.name })
      })
      setSports(sports_array)
    }
    if (getStateData) {
      const status_array = []
      getStateData?.map((a, b) => {
        status_array.push({ value: a.id, label: a.name })
      })
      setStatus(status_array)
    }
  }, [university, sports, getStateData])

  return (
    <>
      <Drawer
        className={style.contentUserDrawer}
        title={
          <div className={style.topTitleDrawer}>
            <h4>
              User Management
              <RightOutlined />
              <span className={style.activelink}>
                {" "}
                {isUpdate ? "Update User" : "Add  New User"}
              </span>
            </h4>
            <div className={style.right}>
              <span onClick={onClose}>
                <CloseOutlined />
              </span>
            </div>
          </div>
        }
        closeIcon={false}
        placement="right"
        onClose={onClose}
        open={open}
        width={720}
        footer={
          <div className={style.btnblock}>
            {isUpdate ? (
              <CommonButton
                loading={updateUserloader}
                title=" Update User"
                onBtnClick={onUpdate}
                type="dark"
              />
            ) : (
              <CommonButton
                loading={requesting}
                title="Add New User"
                onBtnClick={onSubmit}
                type="dark"
              />
            )}
            <CommonButton
              customClass={style.cancelButton}
              title="Cancel"
              onBtnClick={onClose}
              type="dark"
            />
          </div>
        }
      >
        <div className={style.adduserDetails}>
          {isUpdate ? (
            <>
              <Row style={{ gap: 20 }} className={style.btnStyle}>
                <p
                  className={isActive ? style.active : style.deActive}
                  onClick={() => {
                    setIsActive(true)
                    setIsDeActive(false)
                  }}
                >
                  Active
                </p>
                <p
                  onClick={() => {
                    setIsActive(false)
                    setIsDeActive(true)
                  }}
                  className={isDeActive ? style.active : style.deActive}
                >
                  Inactive
                </p>
              </Row>
            </>
          ) : (
            ""
          )}
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <div>
                <p className={style.lableLight}>
                  {" "}
                  First Name <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <CommonInput
                  customClassName={style.inputField}
                  onChange={e => {
                    setFirstName(e.target.value)
                    setFirstNameError("")
                  }}
                  value={firstName}
                  placeholder="First name"
                  type="text"
                />
                {firstName.trim() === "" && firstNameError && (
                  <p style={{ color: "red" }}>{firstNameError}</p>
                )}
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div>
                <p className={style.lableLight}>
                  Last Name <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <CommonInput
                  customClassName={style.inputField}
                  onChange={e => {
                    setLastName(e.target.value)
                    SetLastNameError("")
                  }}
                  value={lastName}
                  placeholder="Last Name"
                  type="text"
                />
                {lastName.trim() === "" && lastNameError && (
                  <p style={{ color: "red" }}>{lastNameError}</p>
                )}
              </div>
            </Col>
            <Col xs={24} md={24}>
              <div>
                <p className={style.lableLight}>
                  Email <span style={{ color: "red" }}>*</span>
                </p>
                <CommonInput
                  onChange={e => {
                    setEmail(e.target.value)
                    setEmailError("")
                  }}
                  value={email}
                  customClassName={style.inputField}
                  placeholder="Email"
                  type="email"
                />
                {email.trim() === "" && emailError && (
                  <p style={{ color: "red" }}>{emailError}</p>
                )}
              </div>
            </Col>
            {/* <Col xs={24} md={12}>
              <div>
                <p className={style.lableLight}>
                  Phone number <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <CommonInput
                  customClassName={style.inputField}
                  onChange={e => {
                    if (e.target.value === "" || Number(e.target.value)) {
                      setPhoneNumber(e.target.value)
                      setPhoneNumberError("")
                    }
                  }}
                  value={phoneNumber}
                  placeholder="Phone number"
                  type="text"
                  maxLength={12}
                />
                {phoneNumber.trim() === "" && phoneNumberError && (
                  <p style={{ color: "red" }}>{phoneNumberError}</p>
                )}
              </div>
            </Col> */}
            {isUpdate ? (
              <>
                <Col xs={24} md={12}>
                  <div>
                    <p className={style.lableLight}>Gender</p>
                    <Select
                      className={style.drawerselect}
                      suffixIcon={<img src={simpleArrowIcon} />}
                      placeholder="Select"
                      popupClassName={style.dropdownstyle}
                      onChange={handelChangeGender}
                      options={[
                        {
                          value: "MALE",
                          label: "Male"
                        },
                        {
                          value: "FEMALE",
                          label: "Female"
                        }
                      ]}
                      value={selectGender}
                    />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div>
                    <p className={style.lableLight}>Date of Birth</p>
                    <DatePicker
                      className={style.dateSelectStyle}
                      onChange={onDateChange}
                      value={dateOfBirth}
                      placeholder="Please Select"
                      format="YYYY-MM-DD"
                    />
                  </div>
                </Col>
                <Col xs={24} md={14}>
                  <div>
                    <p className={style.lableLight}>Street Address </p>
                    <CommonInput
                      customClassName={style.inputField}
                      onChange={e => {
                        setStreetAddress(e.target.value)
                      }}
                      value={streetAddress}
                      placeholder="Address"
                      type="text"
                    />
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div>
                    <p className={style.lableLight}>State</p>
                    <Select
                      className={style.drawerselect}
                      suffixIcon={<img src={simpleArrowIcon} />}
                      placeholder="Select"
                      popupClassName={style.dropdownstyle}
                      onChange={handelChangeState}
                      options={statusData}
                      value={selectedState}
                    />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div>
                    <p className={style.lableLight}>City</p>
                    <CommonInput
                      customClassName={style.inputField}
                      onChange={handelChangeCity}
                      value={selectedCity}
                      placeholder="Search City"
                      type="text"
                    />
                  </div>
                </Col>

                <Col xs={24} md={8}>
                  <div>
                    <p className={style.lableLight}>ZipCode </p>
                    <CommonInput
                      customClassName={style.inputField}
                      onChange={e => {
                        setZipCode(e.target.value)
                      }}
                      value={zipCode}
                      placeholder="Zipcode"
                      type="text"
                    />
                  </div>
                </Col>
                <Col xs={24} md={24}>
                  <div>
                    <p className={style.lableLight}>Bio </p>
                    <Input.TextArea
                      className={style.textAreaStyle}
                      onChange={e => {
                        setBio(e.target.value)
                      }}
                      value={bio}
                      placeholder="Bio"
                    />
                  </div>
                </Col>
              </>
            ) : (
              ""
            )}
            <Col xs={24} md={12}>
              <div>
                <p className={style.lableLight}>
                  Select University <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  className={style.drawerselect}
                  suffixIcon={<img src={arrowIcon} />}
                  placeholder="Select University"
                  popupClassName={style.dropdownstyle}
                  onChange={handleChangeUniversity}
                  options={universityData}
                  value={selectUniversity}
                  showSearch
                  allowClear
                  onSearch={(e) => getUniversity(e)}
                  filterOption={false}
                />
                {selectUniversity?.length === 0 && selectUniversityError && (
                  <p style={{ color: "red" }}>{selectUniversityError}</p>
                )}
              </div>
            </Col>

            <Col xs={24} md={12}>
              <div>
                <p className={style.lableLight}>
                  Select Sports <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  className={style.drawerselect}
                  suffixIcon={<img src={arrowIcon} />}
                  placeholder="Select Sport"
                  popupClassName={style.dropdownstyle}
                  onChange={handleChangeSport}
                  options={sportsData}
                  value={selectSports}
                />
                {selectSports?.length === 0 && selectSportsError && (
                  <p style={{ color: "red" }}>{selectSportsError}</p>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Drawer>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.user.createUserloader,
  createUserError: state.user.error,
  updateUserloader: state.user.updateUserloader
})

const mapDispatchToProps = dispatch => ({
  createUser: (data, onClose) => dispatch(createUser(data, onClose)),
  getUniversity: (data) => dispatch(getUniversity(data)),
  getSports: () => dispatch(getSports()),
  getStates: () => dispatch(getPostState()),
  editUser: (data, onClose) => dispatch(editUser(data, onClose))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserDrawer)
