// @ts-nocheck
import React, { useState } from 'react'
import style from './style.module.scss'
import CommonButton from '../../common/button'
import Logo from '../../assets/Images/Logo.png'
import CommonInput from '../../common/input'
import { useDispatch, useSelector } from 'react-redux'
import { postLogin } from './redux/action'
import {
  useNavigate
} from "react-router-dom"

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const dispatch = useDispatch()
  const { loader, error } = useSelector(state => state.LoginReducer)

  const navigate = useNavigate()
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      HandleSignIn()
    }
  }
  const HandleSignIn = () => {
    const payload = {
      email: email,
      password: password
    }
    dispatch(postLogin(payload, navigate));
    handleKeyPress(payload)
  }

  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <img src={Logo} className={style.logoStyle} alt="logo" />
        <h1>Welcome!</h1>
        <p>Email</p>
        <CommonInput
          customClassName={style.inputStyle}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          erroMessage={error?.email || ''}
          onKeyDown={handleKeyPress}

        />
        <p>Password</p>
        <CommonInput
          customClassName={style.inputStyle}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          type={'password'}
          erroMessage={error?.password || ''}
          onKeyDown={handleKeyPress}
        />
        <div className={style.btnContainer}>
          <CommonButton title="Signin" onBtnClick={HandleSignIn} customClass={style.signinStyle} loading={loader} />
        </div>
      </div>
    </div>
  )
}

export default Login
