// @ts-nocheck
import React, { useState } from 'react'
import style from '../ReOauth/style.module.scss'
import Logo from '../../assets/Images/Logo.png'
const Return = () => {
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <div className={style.center}>

        <img src={Logo} className={style.logoStyle} alt="logo" />
        </div>
       <p>
       After successfully finishing the Connect Onboarding process, our system will direct you to this page. Please be aware that this redirection confirms your successful entry and exit from the process.
        </p> 
<p>
Subsequently, our system will commence the processing of the gathered data. The addition of the payment method will take place once all elements are verified accordingly.
  
  </p>        
      
       
      </div>
    </div>
  )
}

export default Return
