import React, { useEffect, useState } from "react"
import { ProSidebarProvider } from "react-pro-sidebar"
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar"
import { Link } from "react-router-dom"
import sidebarlogo from "../../assets/Images/Logo.png"
import style from "./style.module.scss"
import { useLocation, Navigate, redirect } from "react-router-dom"
import { Images } from "../../themes/sidebarImg"
import menu from "../../assets/menu.png"
import { logOut } from "../../pages/login/redux/action"
import { useDispatch, useSelector } from "react-redux"
import { routes } from "../../Router"
import { toggelAction } from "../../pages/Dashboard/redux/actions"


const SidebarLayout = () => {
  const [activeElement, setActiveElement] = useState("")
  const dispatch = useDispatch()
  const location = useLocation()

  const { toggelSidebar } = useSelector(state => state.Dashboard)

  const logOutUser = () => {
    localStorage.clear()
    dispatch(logOut())
    return redirect('/')
  }

  useEffect(() => {
    setActiveElement(location.pathname)
  }, [location.pathname])

  const renderMenuItem = (item, i) => {
    return (
      <>

        {
          item.adminTitle ? <p className={style.adminAuth}>Admin Authorization</p> : ''
        }
        <MenuItem
          key={i}
          className={style.menuItem}
          onClick={() => {
            setActiveElement(item.path)
            // history.push(item.path);
          }}
        >
          <Link
            to={item.path}
            className={`${style.iconText} ${item.path === activeElement ? style.active : ""}`}
          >
            <div className={style.menuLinkItem}>
              <img src={item.activeIconSrc} className={style.menuiconActive} alt="" />
              <img src={item.iconSrc} className={style.menuIcon} alt="" />

              <div className="text">{item.name}</div>
            </div>
          </Link>
        </MenuItem>
      </>
    )
  }

  return (
    <>

      <Sidebar
        customBreakPoint="992px"
        className={style.sidebar}
        collapsedWidth="0"
        toggled={toggelSidebar}
        onBackdropClick={() => dispatch(toggelAction(false))}
      >
        <div className={style.topMenu}>
          <div className={style.logoHeader}>
            <div className={style.logoWrapper}>
              <img
                src={sidebarlogo}
                alt=""
                style={{ width: 107, height: 32 }}
              />
              <img className={style.menuIcon} alt="menu" src={menu} onClick={() => dispatch(toggelAction(false))} />
            </div>
          </div>
          <Menu>
            {routes.map((item, i) => renderMenuItem(item, i))}
          </Menu>
        </div>
        <div className={style.BottomMenu}>
          <div className={style.iconText} onClick={logOutUser}>
            <img src={Images.logout} alt="" />
            <p className={style.text}>Sign Out</p>
          </div>
          <div className={style.coppyrights}>
            <p>© Onitathlete.com <br /><span>All Right Reserved</span></p>
          </div>
        </div>
      </Sidebar>
    </>
  )
}

const SidebarMenu = () => {
  return (
    <ProSidebarProvider>
      <SidebarLayout />
    </ProSidebarProvider>
  )
}

export default SidebarMenu
