import { all } from "redux-saga/effects"
import LoginSaga from "./pages/login/redux/saga"
import user from "./pages/Management/redux/saga"
import contentManagement from "./pages/ContentManagement/redux/saga"
import salesManagement from "./pages/SalesManagement/redux/saga"
import dashboard from "./pages/Dashboard/redux/saga"
import transactions from "./pages/PayoutManagment/redux/saga"
import helpCenter from "./pages/HelpCenter/redux/saga"

export default function* rootSaga() {
  yield all([
    LoginSaga,
    user,
    contentManagement,
    salesManagement,
    dashboard,
    transactions,
    helpCenter
  ])
}
