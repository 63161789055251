import {
    GET_HELP_CENTER,
    GET_HELP_CENTER_FAIL,
    GET_HELP_CENTER_SUCCESS,
    PATCH_HELP_CENTER,
    PATCH_HELP_CENTER_FAIL,
    PATCH_HELP_CENTER_SUCCESS
} from "./actionTypes"

const initialState = {
    helpCenterData: false,
    helpCenterLoader: false,
    helpCenterError: false,

    patchHelpCenterData: false,
    patchHelpCenterLoader: false,
    patchHelpCenterError: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_HELP_CENTER:
            return {
                ...state,
                helpCenterLoader: true
            }
        case GET_HELP_CENTER_SUCCESS:
            return {
                ...state,
                helpCenterData: action.data,
                helpCenterLoader: false
            }
        case GET_HELP_CENTER_FAIL:
            return {
                ...state,
                error: action.error,
                helpCenterError: false
            }

        case PATCH_HELP_CENTER:
            return {
                ...state,
                patchHelpCenterLoader: true
            }
        case PATCH_HELP_CENTER_SUCCESS:
            return {
                ...state,
                patchHelpCenterData: action.data,
                patchHelpCenterLoader: false
            }
        case PATCH_HELP_CENTER_FAIL:
            return {
                ...state,
                error: action.error,
                patchHelpCenterError: false
            }

        default:
            return state
    }
}
