import React, { useState, useEffect } from 'react'
import style from './style.module.scss'
import { Select } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    getContentManagement
    , getPopularUni,
    getUniversity
} from '../redux/action';

const ContentDataContainer = ({ uniSuccess, UniValue }) => {
    const [UniveristyData, setUniversityData] = useState([])
    const [popularUniData, setPopularUniData] = useState([])
    const [selectUniversty, setSelectUniversty] = useState(null)

    const dispatch = useDispatch()

    const { university, popularUni } = useSelector(state => state.ContentManagement)

    useEffect(() => {
        dispatch(getUniversity())
        dispatch(getPopularUni())
    }, [])

    useEffect(() => {
        if (university) {
            const university_array = []
            university?.map((a, b) => {
                university_array.push({ value: a.id, label: a.name })
            })
            setUniversityData(university_array)
        }
        if (popularUni) {
            const popularUni_array = []
            popularUni?.map((a, b) => {
                popularUni_array.push({ value: a.id, label: a.name })
            })
            setPopularUniData(popularUni_array)
        }
    }, [university, popularUni])

    const handleChangeUniversty = (value) => {
        if(value){
            setSelectUniversty(value)
            dispatch(getContentManagement({ selectUniversty: value }))
            uniSuccess(true)
            UniValue(value)
        }
    }

    const handleChangePopularUniversty = (value) => {
        dispatch(getContentManagement({ selectUniversty: value }))
        uniSuccess(true)
        UniValue(value)
    }

    return (
        <div className={style.contentDataContainer}>
            <div className={style.contentDataStyle}>
                <h1>Hi! User, Please Select Your University </h1>
                <p>Type the keyword of you questions</p>
                <Select
                    className={style.selectStyle}
                    placeholder="Search for Your University"
                    value={selectUniversty}
                    popupClassName={style.selectDropdownstyle}
                    onChange={handleChangeUniversty}
                    options={UniveristyData}
                    showSearch
                    allowClear
                    onSearch={val => dispatch(getUniversity(val))}
                    filterOption={false}
                />
            </div>
            <div className={style.popularContainer}>
                <h1>Most Popular <ArrowRightOutlined /></h1>
                {popularUniData?.map((item, index) => {
                    return (
                        <h2 onClick={() => handleChangePopularUniversty(item?.value)}
                            key={index}>
                            {item?.label}</h2>
                    )
                })}
            </div>
        </div>
    )
}

export default ContentDataContainer
