import React from 'react'
import style from './style.module.scss'
import Logo from '../../assets/Images/Logo.png'

import {
    useNavigate
} from "react-router-dom"

const ReOauth = () => {






    return (
        <div className={style.mainContainer}>
            <div className={style.innerContainer}>
                <div className={style.center}>

                    <img src={Logo} className={style.logoStyle} alt="logo" />
                </div>
                <p>The link you've accessed might redirect you to this URL for various reasons:</p>

                <h4>Reasons for link expiration:</h4>
                <ul>
                    <li><strong>Link Expiry  :  </strong>  If a certain amount of time has passed since the link was generated, it may expire for security reasons.</li>
                    <li><strong>Previous Access : </strong> If you've interacted with the link before, like refreshing the page or revisiting it, you might be redirected.</li>
                    <li><strong>Access Interruption : </strong>  Temporary difficulties accessing your account could trigger redirection for a smoother experience.</li>
                    <li> <strong>Account Rejection : </strong> If your account is suspended or rejected, the link might lead to redirection.</li>
                </ul>

                <h4>Regenerate Process:</h4>
                <h5>To regenerate the link, follow these steps:</h5>
                <ul>
                    <li>Open the mobile app related to your account on your device.</li>
                    <li>Navigate to the "Payments" section within the app.</li>
                    <li>Look for a "+" or "Add Account" button and tap it to initiate adding an account.</li>
                    <li>Inside the account management interface, locate the "Generate Link" option. Clicking it will create a fresh and functional link.</li>
                </ul>

                <p>By following these steps, you can successfully generate a new link, preventing redirection issues and ensuring the link's effectiveness for your intended purpose.</p>

            </div>
        </div>
    )
}

export default ReOauth
