// @ts-nocheck
import React, { useState } from "react"
import style from "./style.module.scss"
import { Drawer } from "antd"
import { CloseOutlined, RightOutlined } from "@ant-design/icons"
import moreIcon from "../../../assets/Images/more.svg"
import copyIcon from "../../../assets/Images/copy.svg"
import Button from "../../../../src/common/button"
import bgtransaction from "../../../assets/Images/Banner.svg"
import money from "../../../assets/Images/money.png"
import { ArrowDownOutlined, UserOutlined } from "@ant-design/icons"
import CommonButton from "../../../../src/common/button"
import moment from "moment"
import html2pdf from "html2pdf.js"
import { toast } from "react-hot-toast"

const TransactionsDetailDrawer = ({ open, onClose, item }) => {
  const [drawerChange, setDrawerChange] = useState(0)

  const firstLetterCap = value => {
    if (value) {
      return value.charAt(0).toUpperCase()
    }
    return value
  }

  const onCopy = value => {
    if (value) {
      navigator.clipboard.writeText(value)
      toast.success("Copied", {
        id: "cards"
      })
    }
  }

  const drawerClose = () => {
    onClose()
    setTimeout(() => {
      setDrawerChange(0)
    }, 500)
  }

  const genrateSlip = () => {
    const element = document.getElementById("capture")
    const opt = {
      margin: 0.25,
      filename: `Onit_Athlete_slip.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
    }
    html2pdf().from(element).set(opt).save()
  }

  return (
    <Drawer
      className={style.contentDrawer}
      title={
        <div className={style.topTitleDrawer}>
          <h4>
            Payment Detail
            <RightOutlined />
            <span className={style.activelink}>Detail</span>
          </h4>
          <div className={style.right}>
            <img src={moreIcon} />
            <span onClick={drawerClose}>
              <CloseOutlined />
            </span>
          </div>
        </div>
      }
      closeIcon={false}
      placement="right"
      onClose={drawerClose}
      open={open}
      width={800}
      footer={
        <div className={style.footerContainer}>
          {drawerChange === 0 && (
            <Button
              title="Next"
              onBtnClick={() => setDrawerChange(1)}
              customClass={style.nextBtnStyle}
            />
          )}
        </div>
      }
    >
      {drawerChange === 0 ? (
        <>
          <div className={style.bodyContainer}>
            <div className={style.userContainer}>
              <div className={style.userimg}>
                {`${firstLetterCap(item?.name || "O")}`}
              </div>
              <h2>{item?.name || ""}</h2>
            </div>
            <div className={style.transactionDetailContainer}>
              <div className={style.transactionDetailStyle}>
                <h1>Transaction ID</h1>
                <h2>
                  {item?.transfer_id || ""}{" "}
                  <img
                    src={copyIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() => onCopy(item?.transfer_id)}
                  />{" "}
                </h2>
              </div>
              <div className={style.transactionDetailStyle}>
                <h1>Date</h1>
                <h2>{`${
                  moment(item?.created_at).format("MMM DD, YYYY") || ""
                } | ${
                  moment(item?.created_at).format("hh:mm:ss A") || ""
                }`}</h2>
              </div>
              <div className={style.transactionDetailStyle}>
                <h1>Payment Methods</h1>
                <h2>Stripe Payment</h2>
              </div>
              <div className={style.transactionDetailStyle}>
                <h1>School</h1>
                <h2></h2>
              </div>
              <div className={style.deviderStyle}></div>
              <div className={style.transactionDetailStyle}>
                <h1>Amount</h1>
                <h2>{`${item?.currency} ${item?.amount}`}</h2>
              </div>
              <div className={style.deviderStyle}></div>
              <div className={style.transactionDetailStyle}>
                <h1>Status</h1>
                <div className={style.statusStyle}>Paid</div>
              </div>
              <div className={style.deviderStyle}></div>
              <div className={style.transactionDetailStyle}>
                <h5>Total Cost</h5>
                <h6>{`${item?.currency} ${item?.amount}`}</h6>
              </div>
            </div>
          </div>
        </>
      ) : (
        drawerChange === 1 && (
          <>
            <div className={style.bgStyle}>
              <div
                id="capture"
                style={{
                  flexGrow: "1",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "20px",
                  borderRadius: "20px",
                  padding: "20px 0",
                  background: "#141c27",
                  margin: "0 auto",
                  width: "100%",
                  height: "100vh"
                }}
              >
                <img
                  src={bgtransaction}
                  style={{
                    maxWidth: "100%",
                    objectFit: "cover",
                    width: "100%",
                    height: "auto"
                  }}
                />
                <div
                  className={style.maindiv}
                  style={{
                    marginTop: "-35px",
                    flexGrow: "1",
                    width: "100%",
                    padding: "0 24px",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <UserOutlined
                    style={{
                      width: "64px",
                      height: "64px",
                      borderRadius: "50%",
                      background: "#65c5c2",
                      color: "#000",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0 auto"
                    }}
                  />
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "16px",
                      color: "#fff",
                      background: "#65c5c2",
                      borderRadius: "14px",
                      padding: "5px 12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      margin: "24px auto",
                      maxWidth: "90px"
                    }}
                  >
                    <span>
                      <img src={money} width={20} height={20} />
                    </span>{" "}
                    Paid{" "}
                  </p>
                  <h3
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      lineHeight: "28px",
                      color: "#fff",
                      textAlign: "center",
                      margin: "18px auto 0 auto"
                    }}
                  >{`${item?.amount || ""} ${
                    item?.currency || ""
                  } to Athelete One`}</h3>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                      color: "#55585a",
                      textAlign: "center",
                      margin: "5px auto 24px auto"
                    }}
                  >
                    {moment(item?.created_at).format("DD MMM YYYY hh:mm A")}
                  </p>
                  <div
                    style={{
                      background: "#181a20",
                      borderRadius: "12px",
                      boxSizing: "border-box",
                      width: "100%",
                      padding: "25px 16px",
                      flexGrow: "1"
                    }}
                  >
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "16px",
                        color: "#55585A",
                        margin: "10px auto",
                        textAlign: "center"
                      }}
                    >
                      Transfer Number
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        color: "#fff",
                        margin: "0 auto 16px auto",
                        textAlign: "center"
                      }}
                    >
                      {item?.transfer_id ? item.transfer_id : "---"}
                    </p>

                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "16px",
                        color: "#55585A",
                        margin: "10px auto",
                        textAlign: "center"
                      }}
                    >
                      Bank Transfer
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        color: "#fff",
                        margin: "0 auto 16px auto",
                        textAlign: "center"
                      }}
                    >
                      {item?.account_id ? item.account_id : "---"}
                    </p>
                  </div>
                </div>
              </div>
              <div className={style.btnDivBlock}>
                <CommonButton
                  title="Download"
                  icon={<ArrowDownOutlined />}
                  type="light"
                  onBtnClick={genrateSlip}
                  customClass={style.addBtn}
                />
              </div>
            </div>
          </>
        )
      )}
    </Drawer>
  )
}

export default TransactionsDetailDrawer
