import XHR from "../../../utils/XHR"
import { BASE_URL } from "../../../config/app"
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getDashboardDataFail, getDashboardDataSuccess } from "./actions";
import { GET_DASHBOARD_DATA } from "./actionTypes";


// GET Dashboard Data

async function getdashboardDataSaga(data) {
    const startDate = data?.start && data?.end ? `?start_date=${data?.start}&` : ""
    const endDate = data?.start && data?.end ? `end_date=${data?.end}` : ""
    let URL = `${BASE_URL}/api/v1/dashboard/${startDate}${endDate}`

    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
        },
        method: "GET"
    }
    return XHR(URL, options)
}



function* getDashboardData({ data }) {
    try {
        const response = yield call(getdashboardDataSaga, data)
        yield put(getDashboardDataSuccess(response.data))
    } catch (error) {
        yield put(getDashboardDataFail(error.response))
    }
}

export default all([
    takeLatest(GET_DASHBOARD_DATA, getDashboardData),
])