export const contentLablesStyle = [
    {
        name: "Report",
        background: "#1E414B",
        color: "#59B4D1"
    },
    {
        name: "Higher Ranked",
        background: "#1E414B",
        color: "#7DC066"
    },
    {
        name: "Fix Bugs",
        background: "#4A2527",
        color: "#E5646C"
    },
    {
        name: "Goalkeeper",
        background: "#4A2527",
        color: "#E5646C"
    },
    {
        name: "Athlete",
        background: "#371F4F",
        color: "#9E57E5"
    },
    {
        name: "New Feature",
        background: "#1E414B",
        color: "#59B4D1"
    },
    {
        name: "Sports",
        background: "#1E414B",
        color: "#59B4D1"
    },
]