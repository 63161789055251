// @ts-nocheck
import React, { useEffect, useState } from "react"
import axios from "axios"
import { BASE_URL } from "../../config/app"
import CommonButton from "../../common/button"
import style from "./style.module.scss"
import facebookIcon from "../../assets/Images/facebook-white.svg"

const FbConnect = () => {
  const [userToken, setUserToken] = useState(null)
  const [message, setMessage] = useState(null)
  const [loader, setLoader] = useState(null)

  const connectToFbAccount = async (accessToken, authToken, userId) => {
    const apiUrl = `${BASE_URL}/api/v1/facebook_connect/?access_token=${accessToken}&user_token=${authToken}&fb_user_id=${userId}`
    try {
      setMessage(null)
      setLoader(true)
      const response = await axios.get(apiUrl)
      if (response.status == 200) {
        if (response.data.msg) {
          setMessage(response.data.msg)
        } else {
          setMessage(
            "You have successfully linked Facebook with ONIT Athlete. Please close this window and open the ONIT Athlete App"
          )
        }
      }
      setLoader(null)
    } catch (error) {
      const { response } = error
      if (response.data.msg) {
        setMessage(response.data.msg)
      } else {
        setMessage("Something went wrong please try again!")
      }
    }
  }

  useEffect(() => {
    if (window.location.search.split("userToken=").length == 2) {
      setUserToken(window.location.search.split("userToken=")[1])
    }

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "1033706721275588",
        cookie: true,
        xfbml: true,
        version: "v18.0"
      })
    }

      ; (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = "https://connect.facebook.net/en_US/sdk.js"
        fjs.parentNode.insertBefore(js, fjs)
      })(document, "script", "facebook-jssdk")
  }, [])

  const launchFBE = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken
          const userID = response.authResponse.userID
          if (accessToken && userID && userToken) {
            connectToFbAccount(accessToken, userToken, userID)
          } else {
            setMessage("Something went wrong please try again!")
          }
        } else {
          setMessage("User cancelled login or did not fully authorize.")
        }
      },
      {
        scope: "pages_show_list,pages_manage_posts,pages_read_engagement"
      }
    )
  }

  return (
    <div className={style.socialBtnContainer}>
      <div className={style.textBtn}>
        {message ? (
          <h3>{message}</h3>
        ) : (
          <>
            <h4>
              To connect with ONIT Athlete, ensure that your Facebook Pages are
              linked to your Meta business account. If not, visit the Meta
              business suite and connect your Facebook Pages.
            </h4>
            <CommonButton
              icon={<img src={facebookIcon} />}
              title="Connect with Facebook"
              onBtnClick={launchFBE}
              customClass={style.btnstyle}
              loading={loader}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default FbConnect
