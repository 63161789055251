// @ts-nocheck
import React, { useState } from "react"
import { CloseOutlined, RightOutlined } from "@ant-design/icons"
import { Carousel, Col, Drawer, Row } from "antd"
import style from "./style.module.scss"
import tagIcon from "../../../assets/Images/tag.svg"
import calendarIcon from "../../../assets/Images/calendar.svg"
import CommonButton from "../../../common/button"
import moment from "moment"
import { contentLablesStyle } from "../../../common/contentLablesStyle"
import pdfIcon from "../../../assets/Images/pdf.svg"
import downloadIcon from "../../../assets/Images/download.svg"
import plyerIcon from "../../../assets/Images/plyer.png"
import { useDispatch, useSelector } from "react-redux"
import { deleteContantRequest } from "../redux/action"
import AntdModal from "../../../Components/AntdModal"
import warning from "../../../assets/Images/warning.png"

const ContentManagementDetail = ({
  open,
  onClose,
  detailData,
  onUpdateDrawer,
  uniValue
}) => {
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteUserId, setDeleteUserId] = useState("")

  const { patchContantLoad, deleteContantLoad } = useSelector(
    state => state.ContentManagement
  )

  const ImagesList = detailData?.attachments

  const onDeleteContent = () => {
    setDeleteModal(true)
  }

  const openURL = url => {
    window.open(url, "_blank").focus()
    return false
  }

  const handleCancelDelete = (e) => {
    setDeleteModal(false);
  };
  const handleDelete = () => {
    if (detailData.id) {
      dispatch(deleteContantRequest(detailData.id, onClose, uniValue, handleCancelDelete))

    }

  };
  return (
    <div>
      <Drawer
        className={style.contentDrawer}
        title={
          <div className={style.topTitleDrawer}>
            <h4>
              {detailData?.status?.name || "--"}
              <RightOutlined />
              <span className={style.activelink}>
                {" "}
                {detailData?.title || "--"}
              </span>
            </h4>
            <div className={style.right}>
              {/* <img src={moreIcon} /> */}
              <span onClick={onClose}>
                <CloseOutlined />
              </span>
            </div>
          </div>
        }
        closeIcon={false}
        placement="right"
        onClose={onClose}
        open={open}
        width={800}
        footer={
          <div className={style.updateDelButton}>
            <CommonButton
              customClass={style.updateBtn}
              loading={patchContantLoad}
              onBtnClick={() => onUpdateDrawer(detailData)}
              type="dark"
              title="Edit"
            />
            <CommonButton
              customClass={style.updateBtn}
              type="dark"
              title="Delete"
              onBtnClick={onDeleteContent}
            />
          </div>
        }
      >
        <div className={style.ContentTitle}>
          <h1>{detailData?.title || "--"}</h1>
        </div>
        <Row className={style.contentDetailContainer}>
          <Col sm={24} lg={4} className={style.lablesStyle}>
            <img src={tagIcon} className={style.labelIconStyle} />
            <h1>Lables</h1>
          </Col>
          <Col sm={24} lg={20} className={style.featureBtnStyle}>
            {detailData?.labels?.length ? (
              detailData?.labels?.map((item, index) => {
                return (
                  <h3
                    style={{
                      background: contentLablesStyle?.find(
                        e => e?.name == item?.name
                      )?.background,
                      color: contentLablesStyle?.find(
                        e => e?.name == item?.name
                      )?.color
                    }}
                    key={index}
                  >
                    {item?.name}
                  </h3>
                )
              })
            ) : (
              <h1>--</h1>
            )}
          </Col>
          <Col sm={24} lg={4} className={style.lablesStyle}>
            <img src={calendarIcon} className={style.labelIconStyle} />
            <h1>Schedule</h1>
          </Col>
          <Col sm={24} lg={20}>
            <h4 className={style.labelDataStyle}>
              {(detailData?.publish_date &&
                moment(detailData?.publish_date).format("DD MMMM YYYY")) ||
                "--"}
            </h4>
          </Col>
          <Col sm={24} lg={4} className={style.lablesStyle}>
            <h1>Category</h1>
          </Col>
          <Col sm={24} lg={20}>
            <h4 className={style.labelDataStyle}>
              {detailData?.category?.name || "--"}
            </h4>
          </Col>
          <Col sm={24} lg={4} className={style.lablesStyle}>
            <h1>Status</h1>
          </Col>
          <Col sm={24} lg={20}>
            <h4 className={style.labelDataStyle}>
              {detailData?.status?.name || "--"}
            </h4>
          </Col>
          <Col sm={24} lg={4} className={style.lablesStyle}>
            <h1>Sports</h1>
          </Col>
          <Col sm={24} lg={20}>
            <h4 className={style.labelDataStyle}>
              {detailData?.sports?.name || "--"}
            </h4>
          </Col>
          <Col sm={24} lg={4} className={style.lablesStyle}>
            <h1>Shopify URL</h1>
          </Col>
          <Col sm={24} lg={20}>
            <h4 className={style.labelDataStyle}>
              {detailData?.shopify_product_url || "--"}
            </h4>
          </Col>
          <Col sm={24} lg={4} className={style.lablesStyle}>
            <h1>Athletes</h1>
          </Col>
          <Col sm={24} lg={20}>
            {detailData?.user?.length ?
              <div className={style.athletesStyle}>
                {detailData?.user?.map((item, index) => {
                  return (
                    <h4 key={index}>
                      {`${item?.first_name ? item?.first_name : "--"} ${item?.last_name ? item?.last_name : "--"}`}
                    </h4>
                  )
                })}
              </div>
              :
              <h4 className={style.labelDataStyle}>---</h4>
            }
          </Col>
        </Row>
        <div className={style.imgVideoContainer}>
          <h1 className={style.title}>Images & Videos</h1>
          <Carousel
            autoplay
            slidesToShow={ImagesList?.length <= 4 ? ImagesList?.length : 4}
            draggable={true}
          >
            {ImagesList?.map((item, index) => {
              return (
                <div key={index} className={style.carouselItemContainer}>
                  {item?.type === "image" ? (
                    <img src={item?.file} className={style.carouselItemStyle} onClick={() => openURL(item?.file)} />
                  ) : (
                    item?.type === "video" && (
                      <img
                        src={plyerIcon}
                        className={style.carouselItemStyle}
                        onClick={() => openURL(item?.file)}
                      />
                    )
                  )}
                </div>
              )
            })}
          </Carousel>
          <div>
            <h1 className={style.title}>FIles</h1>
            {detailData?.attachments?.length ? (
              <Row>
                {detailData?.attachments?.map((item, index) => (
                  item.type === 'other' && (
                    <Col
                      lg={12}
                      md={24}
                      key={index}
                      className={style.fileListContainer}
                    >
                      <div className={style.fileListInnerContainer} onClick={() => openURL(item?.file)}>
                        <div className={style.pdfStyle}>
                          <img src={pdfIcon} />
                        </div>
                        <div className={style.fileContainer}>
                          <h1>{item?.name}</h1>
                          {/* <h2>231.72 KB</h2> */}
                        </div>
                        <div className={style.downloadStyle}>
                          <img src={downloadIcon} />
                        </div>
                      </div>
                    </Col>
                  ))
                )}
              </Row>
            ) : (
              <h1 className={style.title}>----</h1>
            )}
          </div>
        </div>
        <div className={style.contentDescrStyle}>
          <h1 className={style.title}>Content Description</h1>
          <p
            className={style.description}
            dangerouslySetInnerHTML={{
              __html: detailData?.description || "--"
            }}
          ></p>
        </div>
        <div className={style.socialMediaContainer}>
          {/*   <h1 className={style.title}>Social Media Share</h1>
          <div className={style.mediaBtnContainer}>
            {detailData?.social_media_share?.map((item, index) => {
              return (
                <CommonButton
                  key={index}
                  title={item.name || "--"}
                  customClass={style.mediaBtn}
                />
              )
            })}
          </div>*/}
        </div>
      </Drawer>
      {deleteModal && <AntdModal
        modalTitle="Delete"
        isShow={deleteModal}
        onOk={handleDelete}
        onCancel={handleCancelDelete}
        confirmLoading={deleteContantLoad}
        okText='Yes, Delete'
        cancelText='Cancel'
      >
        <div className={style.deletModalContent}>
          <img src={warning} height={97} width={96} />
          <p style={{ color: "#898F96" }}>Are you sure you want to delete this post?</p>
        </div>
      </AntdModal>}
    </div>
  )
}

export default ContentManagementDetail
