import React from 'react'
import { Button } from 'antd'
import style from './style.module.scss'

const CommonButton = ({ title, customClass, onBtnClick, type, icon,loading, disabled }) => {
    return (
        <Button
            className={`${type === "dark" && style.darkbtn ||
                type == "light" && style.lightbtn} ${customClass}`}
            onClick={onBtnClick}
            icon={icon}
            loading={loading}
            disabled={disabled || false}
        >

            {title}
        </Button>
    )
}

export default CommonButton
