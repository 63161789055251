import { GET_USER, GET_USER_SUCCESS, GET_USER_FAIL, CREATE_USER, CREATE_USER_SUCCESS, CREATE_USER_FAIL, DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAILURE, UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE } from "./actionTypes"

export const getUser = data => ({
  type: GET_USER,
  data
})

export const getUserSuccess = data => {
  return {
    type: GET_USER_SUCCESS,
    data
  }
}

export const getUserFail = error => ({
  type: GET_USER_FAIL,
  error
})

export const createUser = (data, onClose) => ({
  type: CREATE_USER,
  data,
  onClose
});
export const createUserSuccess = data => ({
  type: CREATE_USER_SUCCESS,
  data,
});
export const createUserFailure = error => ({
  type: CREATE_USER_FAIL,
  error,
});

export const deleteUser = data => ({
  type: DELETE_USER,
  data,
});
export const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
});
export const deleteUserFailure = error => ({
  type: DELETE_USER_FAILURE,
  error,
});
export const editUser = (data ,onClose) => ({
  type: UPDATE_USER,
  data,
  onClose,
});
export const editUserSuccess = data => ({
  type: UPDATE_USER_SUCCESS,
  data,
});
export const editUserFailure = error => ({
  type: UPDATE_USER_FAILURE,
  error,
});

