import {
    ALL_TRANSACTIONS,
    ALL_TRANSACTIONS_SUCCESS,
    ALL_TRANSACTIONS_FAILURE
  } from "./type"


const initialState = {
transactionData: [],
transactionLoad: false,
transactionError: false
}

export default (state = initialState, {type, data}) => {
    switch (type) {
        case ALL_TRANSACTIONS:
        return {
            ...state,
            transactionLoad: true
        }
        case ALL_TRANSACTIONS_SUCCESS:
        return {
            ...state,
            transactionLoad: false,
            transactionData: data
        }
        case ALL_TRANSACTIONS_FAILURE:
        return {
            ...state,
            transactionLoad: false,
            transactionError: data
        }
        default:
            return state
    }
}