import { all, takeLatest, put, call } from "redux-saga/effects"
import { ALL_TRANSACTIONS } from "./type"
import {
  getAlltransactionsSuccess,
  getAlltransactionsFaliure
} from "./action"
import XHR from "../../../utils/XHR"
import { BASE_URL } from "../../../config/app"

async function transactionAPI(data) {
  const startDate = data?.start && data?.end ? `?start_date=${data?.start}&` : ""
  const endDate = data?.start && data?.end ? `end_date=${data?.end}` : ""
  let URL = `${BASE_URL}/api/v1/admin/transactions/${startDate}${endDate}`
  const token = localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* transactionSaga({ data }) {
  try {
    const response = yield call(transactionAPI, data)
    yield put(getAlltransactionsSuccess(response.data))
  } catch (error) {
    const { response } = error
    yield put(getAlltransactionsFaliure(response.data))
  }
}

export default all([takeLatest(ALL_TRANSACTIONS, transactionSaga)])
