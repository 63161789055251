import { Input } from 'antd'
import React from 'react'
import style from './style.module.scss'

const CommonInput = ({
    value,
    onChange,
    customClassName,
    placeholder,
    type,
    erroMessage,
    maxLength,
    onKeyDown,

}) => {
    return (
        <div>
            <Input
                value={value}
                onChange={onChange}
                className={`${style.commonInput} ${customClassName}`}
                placeholder={placeholder}
                type={type}
                maxLength={maxLength}
                onKeyDown={onKeyDown}

            />
            {erroMessage && (<div className={style.errorMsg}>{erroMessage}</div>)}
        </div>
    )
}

export default CommonInput
