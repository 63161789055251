// @ts-nocheck
import React, { useState, useEffect } from "react"
import HomeLayout from "../../../../web/src/layout/HomeLayout"
import style from "./style.module.scss"
// import linkCard from "../../../assets/Images/linkCard.svg"
// import cardNum from "../../../assets/Images/cardNumber.svg"
// import cardMask from "../../../assets/Images/CardMask.svg"
import receiptItem from "../../assets/Images/receiptItem.svg"
import moreIcon from "../../assets/Images/more.svg"
import { SearchOutlined } from "@ant-design/icons"
import { Col, Input, Row, Select, DatePicker } from "antd"
import TransactionsDetailDrawer from "./TransactionDetailDrawer"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { useDispatch, useSelector } from "react-redux"
import { getAlltransactions } from "./redux/action"
import moment from "moment"


const PayoutManagment = () => {
    const [detailDrawer, setDetailDrawer] = useState(false)
    const [transactionDetail, setTransactionDetail] = useState({})

    const dispatch = useDispatch()

    const { transactionData, transactionLoad } = useSelector(
        state => state.transactions
    )

    const PaidTranscation = transactionData?.paid_transactions
    const UnpaidTranscation = transactionData?.pending_transactions
    const Summary = transactionData?.summary

    useEffect(() => {
        dispatch(getAlltransactions())
    }, [])

    const handleDrawerClose = () => {
        setDetailDrawer(false)
    }

    const firstLetterCap = value => {
        if (value) {
            return value.charAt(0).toUpperCase()
        }
        return value
    }
    const { RangePicker } = DatePicker;
    const Initaildate = {
        start: "",
        end: ""
    }

    const [dateFilter, setDateFilter] = useState(Initaildate)
    const [selectDate, setSelectDate] = useState(null)

    const onDateChange = (date) => {
        const minDate = date && date[0]?.$d
        const maxDate = date && date[1]?.$d
        const payload = {
            start: date ? moment(minDate).format("YYYY-MM-DD") : "",
            end: date ? moment(maxDate).format("YYYY-MM-DD") : ""
        }
        setDateFilter(payload)
        setSelectDate(date)
    }
    useEffect(() => {
        dispatch(getAlltransactions(dateFilter))
    }, [dateFilter])
    return (
        <HomeLayout heading="Payment Management" subHeading="Transactions">
            <TransactionsDetailDrawer
                open={detailDrawer}
                onClose={handleDrawerClose}
                item={transactionDetail}
            />
            <div className={style.mainTransactionsContainer}>
                {/* <div className={style.transactionsCardsContainer}>
                      {CardsData && CardsData?.map((item, index) => {
                          return (
                              <div key={index} className={style.transcationCardStyle} style={{ background: item?.background }}>
                                  <img src={cardMask} className={style.maskStyle} />
                                  <div className={style.valueContainer}>
                                      <h1>${item?.value} <span>UZS</span></h1>
                                      <img src={linkCard} />
                                  </div>
                                  <div className={style.cardNumContainer}>
                                      <img src={cardNum} />
                                      <img src={cardNum} />
                                      <img src={cardNum} />
                                      <h1>{item?.cardNum}</h1>
                                  </div>
                                  <div className={style.cardNameContainer}>
                                      <h1>{item?.name}</h1>
                                      <div className={style.expireContainer}>
                                          <h2>{item?.expDate}</h2>
                                          <h3>EXPIRES</h3>
                                      </div>
                                  </div>
                              </div>
                          )
                      })}
                  </div> */}
                {/* <div className={style.filtersContainer}> */}
                {/* <Input
              className={style.searchInput}
              placeholder="Search"
              prefix={<SearchOutlined />}
            /> */}
                {/* <div className={style.dropdownFilterContainer}>
                          <div className={style.dropdownFilterStyle}>
                              <h1>Date</h1>
                              <Select
                                  className={style.selectStyle}
                                  placeholder="Select"
                                  onChange={onChange}
                                  value="All Data"
                                  onSearch={onSearch}
                                  options={options}
                              />
                          </div>
                          <div className={style.dropdownFilterStyle}>
                              <h1>Type</h1>
                              <Select
                                  className={style.selectStyle}
                                  placeholder="Select"
                                  onChange={onChange}
                                  value="All"
                                  onSearch={onSearch}
                                  options={options}
                              />
                          </div>
                          <div className={style.dropdownFilterStyle}>
                              <h1>STatus</h1>
                              <Select
                                  className={style.selectStyle}
                                  placeholder="Select"
                                  value="All"
                                  onChange={onChange}
                                  onSearch={onSearch}
                                  options={options}
                              />
                          </div>
                          <div className={style.dropdownFilterStyle}>
                              <h1>Sort by</h1>
                              <Select
                                  className={style.selectStyle}
                                  placeholder="Select"
                                  onChange={onChange}
                                  value="Latest"
                                  onSearch={onSearch}
                                  options={options}
                              />
                          </div>
                      </div> */}
                {/* </div> */}
                <Row gutter={24} className={style.transactionsContainer}>

                    <Col span={24} >
                        {/* <div style={{marginBottom:20,display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                        <div span={14} >

                       
                        <h1 style={{marginBottom:0}}>Summary</h1>
                        </div>
                        <div >

                      
                        <RangePicker
                                    className={style.dateSelectStyle}
                                    onChange={onDateChange}
                                    value={selectDate}
                                    format={"YYYY-MM-DD"}
                                />
                                  </div>
                        </div> */}
                        <Row style={{ alignItems: "baseline" }}>
                            <Col lg={16} md={24} style={{ marginBottom: 40 }}>
                                {" "}
                                <h1>Summary</h1>

                            </Col>
                            <Col lg={8} md={24}>
                                <RangePicker
                                    className={style.dateSelectStyle}
                                    onChange={onDateChange}
                                    value={selectDate}
                                    popupClassName={style.rangeStyle}
                                    format={"DD MMM YYYY"}
                                />
                            </Col>
                        </Row>
                        {transactionLoad ? (
                            <SkeletonTheme baseColor="#1f2732" highlightColor="#5c6f87">
                                <div className={style.summaryContainer}>
                                    <div className={style.paidUnpaidContainer}>
                                        <div className={style.summaryStyle}>
                                            <h1>Paid Transactions</h1>
                                            <h2 style={{ width: '80px', margin: '0 auto' }}><Skeleton style={{ display: "flex", borderRadius: "12px" }} height={10} count={1} /></h2>
                                        </div>
                                        <div className={style.summaryStyle}>
                                            <h1>UnPaid Transactions</h1>
                                            <h2 style={{ width: '80px', margin: '0 auto' }}><Skeleton style={{ display: "flex", borderRadius: "12px" }} height={10} count={1} /></h2>
                                        </div>
                                    </div>
                                    <div className={style.totalSummaryStyle}>
                                        <h1>Total Transactions</h1>
                                        <h2 style={{ width: '80px', margin: '0 auto' }}><Skeleton style={{ display: "flex", borderRadius: "12px" }} height={10} count={1} /></h2>
                                    </div>
                                </div>
                            </SkeletonTheme>
                        ) :
                            <div className={style.summaryContainer}>
                                <div className={style.paidUnpaidContainer}>
                                    <div className={style.summaryStyle}>
                                        <h1>Paid Transactions</h1>
                                        <h2>USD {Summary?.paid_payment || 0}</h2>
                                    </div>
                                    <div className={style.summaryStyle}>
                                        <h1>UnPaid Transactions</h1>
                                        <h2>USD {Summary?.unpaid_payment || 0}</h2>
                                    </div>
                                </div>
                                <div className={style.totalSummaryStyle}>
                                    <h1>Total Transactions</h1>
                                    <h2>USD {Summary?.total_payment || 0}</h2>
                                </div>
                            </div>
                        }
                        {/* <TransactionCards /> */}
                    </Col>
                    <Col span={24} lg={15}>
                        <h1>Paid Transactions</h1>
                        <div className={style.transactionContainer}>
                        {transactionLoad ? (
                            <SkeletonTheme baseColor="#1f2732" highlightColor="#5c6f87">
                                {[1, 2, 3, 4, 5]?.map(item => (
                                    <>
                                        <div className={style.transactionsStyle}>
                                            <div className={style.receiptContainer}>
                                                <div className={style.receiptStyle}>
                                                    <img src={receiptItem} />
                                                </div>
                                                <Skeleton style={{ borderRadius: "12px" }} width={50} height={10} count={1} />
                                            </div>
                                            <Skeleton style={{ borderRadius: "12px" }} width={50} height={10} count={1} />
                                            <div className={style.userContainer}>
                                                <Skeleton style={{ borderRadius: "12px" }} width={120} height={10} count={1} />
                                            </div>
                                            <Skeleton style={{ borderRadius: "12px" }} width={50} height={10} count={1} />
                                            <div className={style.statusContainer}>
                                                <Skeleton style={{ borderRadius: "12px" }} width={50} height={10} count={1} />
                                            </div>
                                            <div className={style.valueContainer}>
                                                <Skeleton style={{ borderRadius: "12px" }} height={10} width={50} count={1} />
                                                <img
                                                    src={moreIcon}
                                                />
                                            </div>
                                        </div>
                                    </>)
                                )}
                            </SkeletonTheme>
                        ) : (PaidTranscation?.length ?
                                PaidTranscation?.map((item, index) => (
                                
                                        
                              
                                <div
                                    key={index}
                                    className={style.transactionsStyle}
                                    onClick={() => {
                                        setTransactionDetail(item)
                                        setDetailDrawer(true)
                                    }}
                                >
                                    <div className={style.receiptContainer}>
                                        <div className={style.receiptStyle}>
                                            <img src={receiptItem} />
                                        </div>
                                        <h2>{moment(item?.date).format("DD MMM YYYY") || ""}</h2>
                                    </div>
                                    <h2>{item?.id || ""}</h2>
                                    <div className={style.userContainer}>
                                        <div className={style.userimg}>
                                            {`${firstLetterCap(item?.name || "-")}`}
                                        </div>
                                        <h2>{`${item?.name || "---"}`}</h2>
                                    </div>
                                    <h3>Stripe Payment</h3>
                                    <div className={style.statusContainer}>
                                        <h3
                                            className={style.statusStyle}
                                            style={{
                                                color: "#65C5C2",
                                                borderColor: "#65C5C2"
                                            }}
                                        >
                                            Paid
                                        </h3>
                                    </div>
                                    <div className={style.valueContainer}>
                                        <h1>{`${item?.currency || ""} ${item?.amount || ""}`}</h1>
                                        <img
                                            src={moreIcon}
                                        />
                                    </div>
                                </div>
                                        
                            ))
                            :
                            <h1 style={{ textAlign: 'center', marginTop: "30px" }}>No data found</h1>
                            )}
                            </div>
                    </Col>
                    <Col span={24} lg={9} className={style.chartScheduleContainer}>
                        <div className={style.schedulePaymentContainer}>
                            <h1>Unpaid Transactions</h1>
                            {transactionLoad ? (
                                <SkeletonTheme baseColor="#1f2732" highlightColor="#5c6f87">
                                    {[1, 2, 3, 4]?.map(item => (
                                        <>
                                            <div className={style.scheduleStyle}>
                                                <div className={style.imgContainer}>
                                                    <Skeleton style={{ borderRadius: "12px" }} width={36} height={36} />
                                                </div>
                                                <div className={style.titleContainer}>
                                                    <Skeleton style={{ borderRadius: "12px" }} height={10} width={100} />
                                                </div>
                                                <Skeleton style={{ borderRadius: "12px" }} height={5} width={20} />
                                            </div>
                                        </>)
                                    )}
                                </SkeletonTheme>
                            ) : (
                                UnpaidTranscation?.length ?
                                    UnpaidTranscation?.map((item, index) => {
                                        return (
                                            <div key={index} className={style.scheduleStyle}>
                                                <div className={style.imgContainer}>

                                                    {`${firstLetterCap(item?.name || "N")}`}
                                                </div>
                                                <div className={style.titleContainer}>
                                                    <h1>{item?.name || "---"}</h1>
                                                    {/* <h3>{item?.date}</h3> */}
                                                </div>
                                                <h2>USD {item?.pending || 0}.00</h2>
                                            </div>
                                        )
                                    }) :
                                    <h1 style={{ textAlign: 'center', marginTop: "30px" }}>No data found</h1>
                            )
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </HomeLayout>
    )
}

export default PayoutManagment