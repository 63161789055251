// @ts-nocheck
import React, { useEffect } from "react"
import style from "./style.module.scss"
import HomeLayout from "../../layout/HomeLayout"
import { Tabs } from "antd"
import { useDispatch } from "react-redux"
import { getHelpCenter } from "./redux/action"
import { useSelector } from "react-redux"
import { useState } from "react"
import ReplayDrawer from "./ReplayDrawer"


const Anayltics = () => {
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [messageId, setMessageId] = useState(null)

  const dispatch = useDispatch()
  const { helpCenterData, patchHelpCenterLoader, patchHelpCenterData } = useSelector(state => state.helpCenter)

  useEffect(() => {
    dispatch(getHelpCenter(""))
  }, [patchHelpCenterData])

  const TicketsData = helpCenterData && helpCenterData?.filter(e => e?.type == "ticket")
  const SuggestionsData = helpCenterData && helpCenterData?.filter(e => e?.type == "feature")

  const TabsData = ({ data }) => {
    return (
      <>
        {data?.length && data?.map((item, index) => {
          return (
            <div key={index} className={style.messageContainer}>
              <div>
                <h1 className={style.messageTitle}> Email: <span> {item?.user?.email || "---"} </span></h1>
                <h1 className={style.messageTitle}> Message: <span> {item?.message || "---"}</span></h1>
              </div>
              {item?.reply ?
                <h1 className={style.repliedTitle}>Replied</h1>
                :
                <h1
                  className={style.replyTitle}
                  onClick={() => {
                    setOpen(true)
                    setEmail(item?.user?.email)
                    setMessageId(item?.id)
                  }}
                >
                  Reply
                </h1>
              }
            </div>
          )
        })}
      </>
    )
  }

  const tabsHeader = [
    {
      key: '1',
      label: 'Tickets',
      children: <TabsData data={TicketsData} />,
    },
    {
      key: '2',
      label: 'Suggestions',
      children: <TabsData data={SuggestionsData} />,
    },
  ];

  return (
    <>
      <HomeLayout>
        <div className={style.mainWrapper}>
          <h1>Help Center</h1>
          <Tabs defaultActiveKey="1" items={tabsHeader} />
        </div>
        <ReplayDrawer
          open={open}
          handleClose={() => setOpen(false)}
          email={email}
          id={messageId}
          loader={patchHelpCenterLoader}
        />
      </HomeLayout>
    </>
  )
}
export default Anayltics
