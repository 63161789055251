import {
    AFFILIATE_USER,
    AFFILIATE_USER_FAILURE,
    AFFILIATE_USER_SCUCCESS,
    GET_Sales_LIST,
    GET_Sales_LIST_FAILURE,
    GET_Sales_LIST_SUCCESS
} from "./actionTypes"

const initialState = {
    salesManagementData: [],
    loader: false,
    error: "",

    affiliateUserData: false,
    affiliateUserLoader: false,
    affiliateUserError: "",
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_Sales_LIST:
            return {
                ...state,
                loader: true
            }
        case GET_Sales_LIST_SUCCESS:
            return {
                ...state,
                salesManagementData: action.data,
                loader: false
            }
        case GET_Sales_LIST_FAILURE:
            return {
                ...state,
                error: action.error,
                loader: false
            }


        case AFFILIATE_USER:
            return {
                ...state,
                affiliateUserLoader: true
            }
        case AFFILIATE_USER_SCUCCESS:
            return {
                ...state,
                affiliateUserData: action.data,
                affiliateUserLoader: false
            }
        case AFFILIATE_USER_FAILURE:
            return {
                ...state,
                affiliateUserError: action.error,
                affiliateUserLoader: false
            }
        default:
            return state
    }
}