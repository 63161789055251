import {
    GET_DASHBOARD_DATA,
    GET_DASHBOARD_DATA_FAIL,
    GET_DASHBOARD_DATA_SUCCESS,
    TOGGEL_SIDEBAR
} from "./actionTypes"

const initialState = {
    dashboardData: false,
    loader: false,
    error: false,

    toggelSidebar: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                loader: true
            }
        case GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                dashboardData: action.data,
                loader: false
            }
        case GET_DASHBOARD_DATA_FAIL:
            return {
                ...state,
                error: action.error,
                loader: false
            }
        case TOGGEL_SIDEBAR:
            return {
                toggelSidebar: action.data
            }
        default:
            return state
    }
}

