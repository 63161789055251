import React, { useState } from 'react';
import { Button, Modal } from 'antd';

const CommonModal = ({ okText,confirmLoading, cancelText, buttonText, isShow, onOk, onCancel, modalTitle, children }) => {


  return (
    <>
      <div className='antdModal'>
        <Modal
          title={modalTitle}
          open={isShow}
          onOk={onOk}
          onCancel={onCancel}
          okText={okText}
          confirmLoading={confirmLoading}
          cancelText={cancelText}
        >
          {children}
        </Modal>
      </div>
    </>
  );
};

export default CommonModal;
