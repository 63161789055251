import React from 'react'
import Skeleton from 'react-loading-skeleton'
import style from './style.module.scss'

export const SalesClicksFollowersSkeleton = () => {
    return (
        <div className={style.SalesSkeletonStyle}>
            <Skeleton
                baseColor="#1F2732"
                highlightColor="#121617"
                circle
                height={30}
                width={30}
            />
            <div className={style.title}>
                <Skeleton
                    baseColor="#1F2732"
                    highlightColor="#121617"
                    height={20}
                    width={60}
                />
                <div className={style.values}>
                    <Skeleton
                        baseColor="#1F2732"
                        highlightColor="#121617"
                        height={40}
                        width={170}
                    />
                    <Skeleton
                        baseColor="#1F2732"
                        highlightColor="#121617"
                        height={25}
                        width={180}
                    />
                </div>
            </div>
        </div>
    )
}


export const EngagementRateSkeleton = () => {
    return (
        <div className={style.engagementRateSkeleton}>
            <div className={style.titleStyle}>
                <Skeleton
                    baseColor="#1F2732"
                    highlightColor="#121617"
                    circle
                    height={23}
                    width={23}
                />
                <Skeleton
                    baseColor="#1F2732"
                    highlightColor="#121617"
                    height={20}
                    width={100}
                />
            </div>
            <div className={style.values}>
                <Skeleton
                    baseColor="#1F2732"
                    highlightColor="#121617"
                    height={30}
                    width={100}
                />
                <Skeleton
                    baseColor="#1F2732"
                    highlightColor="#121617"
                    height={20}
                    width={80}
                    borderRadius={10}
                />
            </div>
        </div>
    )
}


export const LinksReportSkeleton = () => {
    return (
        [1, 2, 3, 4, 5, 6, 6, 5, 5, 4, 4]?.map(() => {
            return (
                <div className={style.linksReportSkeleton}>
                    <div className={style.description}>
                        <Skeleton
                            baseColor="#1F2732"
                            highlightColor="#121617"
                            height={20}
                            width={220}
                        />
                    </div>
                    <Skeleton
                        baseColor="#1F2732"
                        highlightColor="#121617"
                        height={20}
                        width={60}
                    />
                </div>
            )
        })
    )
}

export const RegisterdUsersSkeleton = () => {
    return (
        <div className={style.registerdUsersSkeleton}>
            <Skeleton
                baseColor="#1F2732"
                highlightColor="#121617"
                height={30}
                width={60}
            />
            <br />
            <Skeleton
                baseColor="#1F2732"
                highlightColor="#121617"
                height={25}
                width={100}
            />
            <br />
            <Skeleton
                baseColor="#1F2732"
                highlightColor="#121617"
                height={20}
                width={120}
            />
        </div>
    )
}