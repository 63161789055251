import React from 'react'
import ReactApexChart from 'react-apexcharts'
import style from './style.module.scss'
import moment from 'moment'

const SalesReportChart = ({ loader, data }) => {
    const chartData = data?.length > 20 ? data?.filter((_, i) => i % 2 === 1) : data
    const XaxisValue = chartData?.map(e => moment(e?.date).format("DD MMM") || "-")
    const Orders = chartData?.map(e => e?.orders || 0)
    const Visits = chartData?.map(e => e?.visits || 0)
    const maxVal = Orders && Visits && Math.max(...Orders, ...Visits)
    const YaxisValue = maxVal ? maxVal + maxVal / 5 : 6

    const ChartState = {
        series: [{
            name: "Orders",
            data: loader ? [1, 2, 3, 3, 4, 5] : Orders
        }, {
            name: "Visits",
            data: loader ? [2, 3, 4, 5, 3, 2] : Visits
        }],
        options: {
            chart: {
                height: 200,
                type: 'area',
            },
            legend: {
                show: false
            },
            colors: loader ? ["#181A20", "#181a20"] : ["#B822FF", "#1DB2F1"],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: loader ? ["X 01", "X 02", "X 03", "X 04", "X 05", "X 06"] : XaxisValue,
            },
            grid: {
                show: true,
                position: "back",
                borderColor: '#55585A',
                strokeDashArray: 2,
            },
            yaxis: {
                min: 0,
                tickAmount: 8,
                labels: {
                    formatter: function (val) {
                        return val.toFixed(0)
                    }
                }
            }
        },


    };

    return (
        <div className={style.SaleschartContainer}>
            {loader || chartData?.length ?
                <ReactApexChart options={ChartState.options} series={ChartState.series} type="area" height={'100%'} />
                :
                <h1 className={style.noDataStyle}>No Data Found</h1>
            }
        </div>
    )
}

export default SalesReportChart
