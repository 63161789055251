import {
    AFFILIATE_USER,
    AFFILIATE_USER_FAILURE,
    AFFILIATE_USER_SCUCCESS,
    GET_Sales_LIST,
    GET_Sales_LIST_FAILURE,
    GET_Sales_LIST_SUCCESS
} from "./actionTypes"

export const getSalesList = (data, id, minDate, maxDate, limit, offset) => ({
    type: GET_Sales_LIST,
    data,
    id,
    minDate,
    maxDate,
    limit,
    offset
})

export const getSalesListSuccess = data => ({
    type: GET_Sales_LIST_SUCCESS,
    data
})

export const getSalesListFailure = error => ({
    type: GET_Sales_LIST_FAILURE,
    error
})

export const affiliateUser = (data) => ({
    type: AFFILIATE_USER,
    data
})

export const affiliateUserSuccess = data => ({
    type: AFFILIATE_USER_SCUCCESS,
    data
})

export const affiliateUserFailure = error => ({
    type: AFFILIATE_USER_FAILURE,
    error
})