import {
  GET_CONTENT_MANAGEMENT,
  GET_CONTENT_MANAGEMENT_SUCCESS,
  GET_CONTENT_MANAGEMENT_FAILURE,
  GET_LABELS,
  GET_LABELS_SUCCESS,
  GET_LABELS_FAILURE,
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILURE,
  GET_UNIVERSITY,
  GET_UNIVERSITY_SUCCESS,
  GET_UNIVERSITY_FAILURE,
  GET_SPORTS,
  GET_SPORTS_SUCCESS,
  GET_SPORTS_FAILURE,
  GET_CONTENT_MANAGEMENT_DETAIL,
  GET_CONTENT_MANAGEMENT_SUCCESS_DETAIL,
  GET_CONTENT_MANAGEMENT_FAILURE_DETAIL,
  GET_SOCIAL_MEDIA_SHARE,
  GET_SOCIAL_MEDIA_SHARE_SUCCESS,
  GET_SOCIAL_MEDIA_SHARE_FAILURE,
  POST_NEW_CONTANT,
  POST_NEW_CONTANT_SUCCESS,
  POST_NEW_CONTANT_FAILURE,
  PATCH_CONTANT_REQUEST,
  PATCH_CONTANT_REQUEST_SUCCESS,
  PATCH_CONTANT_REQUEST_FAILURE,
  DELETE_CONTANT_REQUEST,
  DELETE_CONTANT_REQUEST_SUCCESS,
  DELETE_CONTANT_REQUEST_FAILURE,
  GET_POPULAR_UNI,
  GET_POPULAR_UNI_SUCCESS,
  GET_POPULAR_UNI_FAILURE,
  GET_STATUS,
  GET_STATUS_SUCCESS,
  GET_STATUS_FAILURE,
  GET_STATE,
  GET_STATE_FAILURE,
  GET_STATE_SUCCESS
} from "./actionTypes"

// GET Content Management Actions

export const getContentManagement = data => ({
  type: GET_CONTENT_MANAGEMENT,
  data
})

export const getContentManagementSuccess = data => ({
  type: GET_CONTENT_MANAGEMENT_SUCCESS,
  data
})

export const getContentManagementFailure = error => ({
  type: GET_CONTENT_MANAGEMENT_FAILURE,
  error
})

// GET Content Management Detail Actions

export const getContentManagementDetail = (id, data) => ({
  type: GET_CONTENT_MANAGEMENT_DETAIL,
  id,
  data
})

export const getContentManagementSuccessDetail = data => ({
  type: GET_CONTENT_MANAGEMENT_SUCCESS_DETAIL,
  data
})

export const getContentManagementFailureDetail = error => ({
  type: GET_CONTENT_MANAGEMENT_FAILURE_DETAIL,
  error
})

// Labels Actions

export const getLabels = data => ({
  type: GET_LABELS,
  data
})

export const getLabelsSuccess = data => ({
  type: GET_LABELS_SUCCESS,
  data
})

export const getLabelsFailure = error => ({
  type: GET_LABELS_FAILURE,
  error
})

// Category Actions

export const getCategory = data => ({
  type: GET_CATEGORY,
  data
})

export const getCategorySuccess = data => ({
  type: GET_CATEGORY_SUCCESS,
  data
})

export const getCategoryFailure = error => ({
  type: GET_CATEGORY_FAILURE,
  error
})

// Univeristy Actions

export const getUniversity = data => ({
  type: GET_UNIVERSITY,
  data
})

export const getUniversitySuccess = data => ({
  type: GET_UNIVERSITY_SUCCESS,
  data
})

export const getUniversityFailure = error => ({
  type: GET_UNIVERSITY_FAILURE,
  error
})

// Popluar Actions

export const getPopularUni = data => ({
  type: GET_POPULAR_UNI,
  data
})

export const getPopularUniSuccess = data => ({
  type: GET_POPULAR_UNI_SUCCESS,
  data
})

export const getPopularUniFailure = error => ({
  type: GET_POPULAR_UNI_FAILURE,
  error
})

// Sports Actions

export const getSports = data => ({
  type: GET_SPORTS,
  data
})

export const getSportsSuccess = data => ({
  type: GET_SPORTS_SUCCESS,
  data
})

export const getSportsFailure = error => ({
  type: GET_SPORTS_FAILURE,
  error
})

// Social Media Share Actions

export const getSocialMediaShare = data => ({
  type: GET_SOCIAL_MEDIA_SHARE,
  data
})

export const getSocialMediaShareSuccess = data => ({
  type: GET_SOCIAL_MEDIA_SHARE_SUCCESS,
  data
})

export const getSocialMediaShareFailure = error => ({
  type: GET_SOCIAL_MEDIA_SHARE_FAILURE,
  error
})
export const postNewContant = (data, handleClose, draft, uniValue, CheckScheduleDate) => ({
  type: POST_NEW_CONTANT,
  data,
  handleClose,
  draft,
  uniValue,
  CheckScheduleDate
})

export const postNewContantSuccess = data => ({
  type: POST_NEW_CONTANT_SUCCESS,
  data
})

export const postNewContanteFailure = error => ({
  type: POST_NEW_CONTANT_FAILURE,
  error
})

export const patchContantRequest = (id, data, draft, uniValue, CheckScheduleDate, status) => ({
  type: PATCH_CONTANT_REQUEST,
  id,
  data,
  draft,
  uniValue,
  CheckScheduleDate,
  status
})

export const patchContantRequestSuccess = data => ({
  type: PATCH_CONTANT_REQUEST_SUCCESS,
  data
})

export const patchContantRequestFailure = data => ({
  type: PATCH_CONTANT_REQUEST_FAILURE,
  data
})

export const deleteContantRequest = (id, onClose, uniValue, handleCancelDelete) => ({
  type: DELETE_CONTANT_REQUEST,
  id,
  onClose,
  uniValue,
  handleCancelDelete
})

export const deleteContantRequestSuccess = () => ({
  type: DELETE_CONTANT_REQUEST_SUCCESS,
})

export const deleteContantRequestFailure = data => ({
  type: DELETE_CONTANT_REQUEST_FAILURE,
  data
})


export const getPostStatus = (data) => ({
  type: GET_STATUS,
  data,
})

export const getStatusSuccess = (data) => ({
  type: GET_STATUS_SUCCESS,
  data
})

export const getStatusFailure = data => ({
  type: GET_STATUS_FAILURE,
  data
})

export const getPostState = (data) => ({
  type: GET_STATE,
  data,
})

export const getStateSuccess = (data) => ({
  type: GET_STATE_SUCCESS,
  data
})

export const getStateFailure = data => ({
  type: GET_STATE_FAILURE,
  data
})